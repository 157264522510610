import React, { useState, useContext, useEffect, useRef, useLayoutEffect } from 'react';
import { Modal, Container, Image, ProgressBar, ButtonGroup, Row, Col, Form, Badge, Button, Card, InputGroup, Spinner, Nav, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmployesDomainView from '../../pages/EmployesDomainView';
import WorkjobView from '../../pages/WorkjobView';
import ImportEmployersView from '../../pages/ImportEmployersView';
import ClothingDomainView from '../../pages/ClothingDomainView';


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalDomainEmployersManagment = ({ t, uData, contract_list }) => {
    const [showEditUsers, setShowUsers] = useState(false);
    const handleCloseUsers = () => setShowUsers(false);
    const [editedElement, setEditedElement] = useState(false)
    const [curSection, setCurSection] = useState('employer')
    const handleSection = (s) => {
        setCurSection(s)
    }
    return (
        <>
            <Button variant="outline-info" onClick={() => setShowUsers(true)}>
                <FontAwesomeIcon icon="id-card-alt" /> Dipendenti
            </Button>
            <Modal show={showEditUsers} onHide={handleCloseUsers} fullscreen backdrop="static" className="bg-secondary" scrollable={true}>
                <Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
                    <Modal.Title>
                        Gestione dipendenti
                        <ButtonGroup>
                            <Button size="sm" onClick={() => setCurSection('workjob')} variant={curSection === 'workjob' ? 'info' : 'outline-secondary'}>Mansioni</Button>
                            <Button size="sm" onClick={() => setCurSection('employer')} variant={curSection === 'employer' ? 'info' : 'outline-secondary'}>Dipendenti</Button>
                            <Button size="sm" onClick={() => setCurSection('clothing')} variant={curSection === 'clothing' ? 'info' : 'outline-secondary'}>Abbigliamento</Button>
                        </ButtonGroup>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        {curSection === 'workjob' &&
                            <WorkjobView t={t} uData={uData} />
                        }
                        {curSection === 'clothing' &&
                            <ClothingDomainView t={t} uData={uData} />
                        }
                        {curSection === 'import' &&
                            <ImportEmployersView t={t} uData={uData} switchSection={handleSection} />
                        }
                        {curSection === 'employer' &&
                            <EmployesDomainView t={t} uData={uData} contract_list={contract_list} switchSection={handleSection} />
                        }
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUsers}>
                        {t('modal.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}