import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Row, Col, Button, Modal, Form, InputGroup, Nav, Tab, ProgressBar, Image, ButtonGroup, Spinner } from 'react-bootstrap';
import { translate } from 'react-polyglot'

import firebase from 'firebase/compat/app'
import { addDomain as addDomain2, editDomain as editDomain2 } from '../../Firebase2/Storage2/dbDomain';
import { uploadFileComplete as uploadFileComplete2 } from '../../Firebase2/Storage2/dbFileStorage';
import { contractScope_dict, contractType_dict, contractSector_dict } from '../constants/global_definitions';
import { ModalInitButton } from '../StructureGraphicElements';
import { addGlobalImage, addImage, editGlobalImage, editImage } from '../../Firebase2/Storage2/dbImage';
import DomainContext from '../contexts/domainContext';
import { checkComponentPresence } from '../../util/ui_utils';


const all = (arr, fn = Boolean) => arr.every(fn);



function ModalRoomTypeImage({ item, t, is_new, small, index, item_list, setParentList, canWrite, is_global }) {

	const newroomtypeimage = {
		description: '',
		contract_type_list: [],
		contract_scope_list: [],
		creation_date: firebase.firestore.Timestamp.now(),

	}
	const [showRoomTypeImageEdit, setShowRoomTypeImageEdit] = useState(false)
	const [curElement, setCurElement] = useState(newroomtypeimage)
	const [editedElement, setEditedElement] = useState(false)
	const [previewList, setPreviewList] = useState([])

	const [uploadingState, setUploadingState] = useState([0])
	const [is_multi, setMulti] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')

	const handleCloseRoomTypeImage = () => setShowRoomTypeImageEdit(false);

	const cb_progress = (status, byte, total, index) => {
		console.log(status, byte, total)
		try {
			setUploadingState(s => {
				let _s = [...s]
				_s.splice(index || 0, 1, 100 * byte / total)
				return _s
			})
		} catch (e) {
			console.warn("handleSaveMedia - error: ", e)
		}
	}


	const handleShowNewRoomTypeImage = () => {
		console.log('show new')
		setMulti(false)
		setErrorMsg('')
		setCurElement(newroomtypeimage)
		setShowRoomTypeImageEdit(true)
		setUploadingState([0])
		setEditedElement(false)
	}


	const handleShowEditRoomTypeImage = () => {
		if (!item.color) {
			item.color = { ...newroomtypeimage.color }
		}
		setCurElement(item)
		setShowRoomTypeImageEdit(true)
		setTimeout(function () {
			setEditedElement(false)
		}, 100)
	}
	useEffect(() => {
		if (!curElement.file) {
			setPreviewList([])
			return
		}
		let url_list = []
		for (const f of curElement.file) {
			url_list.push([URL.createObjectURL(f)])
		}
		setPreviewList(url_list)

		// free memory when ever this component is unmounted
		return () => {
			for (const p of previewList) {
				URL.revokeObjectURL(p)
			}
		}
	}, [curElement.file])


	useEffect(() => {
		console.log()
		if (is_multi && curElement.file && uploadingState.length === curElement.file.length && all(uploadingState, x => x >= 100)) {
			setShowRoomTypeImageEdit(false)
		}

	}, [uploadingState])


	const onChangeHandler = (el) => {
		let { name, value, type } = el
		if (type === 'file') {
			console.log(el.files)
			value = el.files
			name = 'file'
			if (value.length > 1) {
				let d_list = []
				let o_list = []
				for (const f of value) {
					console.log(f)
					d_list.push(f.name.split('.')[0].toLowerCase().replace(/_/g, " "))
					o_list.push(f.name)
				}
				console.log(d_list)
				setCurElement(e => ({ ...e, file: el.files, description_list: d_list, original_name_list: o_list }))

			} else {
				if (value.length > 0) {
					setCurElement(e => ({ ...e, file: el.files, description: el.files[0].name.split('.')[0].toLowerCase().replace(/_/g, " "), original_name: el.files[0].name }))
				} else {
					setCurElement(e => ({ ...e, file: el.files, description: '' }))
				}
			}

			setEditedElement(true)
		} else {
			if (type === 'radio') {
				if (value === 'true') {
					value = true
				} else if (value === 'false') {
					value = false
				}
			} else if (type === 'checkbox') {
				let oldcheck = []
				try {
					oldcheck = [...curElement[name]]
				} catch (err) { }
				if (el.checked) {
					if (oldcheck.indexOf(value) < 0) {
						oldcheck.push(value)
					}
				} else {
					if (oldcheck.indexOf(value) >= 0) {
						oldcheck.splice(oldcheck.indexOf(value), 1)
					}
				}
				value = oldcheck

			}
			console.log(name, value)
			setCurElement(e => ({ ...e, [name]: value }))
			setEditedElement(true)
		}
	}


	const isDisabled = () => {
		if (!curElement.Id && !curElement.file) {
			console.log("curElement", curElement)
			setErrorMsg('file mancante')
			return true
		}
		if ((is_multi && !curElement.description_list) || (!is_multi && curElement.description.length === 0)) {
			setErrorMsg("descrizione mancante")
			return true
		}
		setErrorMsg('')
		return false
	}


	useEffect(() => {

	}, [curElement])

	return (
		<>
			<ModalInitButton t={t} is_new={is_new} small={small} title_new={is_global ? "carica nuova immagine globale" : "carica nuova immagine di dominio"} action_new={handleShowNewRoomTypeImage} action_edit={handleShowEditRoomTypeImage} action_info={handleShowEditRoomTypeImage} canWrite={canWrite} />
			<Modal show={showRoomTypeImageEdit} onHide={handleCloseRoomTypeImage} fullscreen backdrop="static" className="bg-secondary" scrollable>
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					{is_new && <Modal.Title className="text-primary">{t('licence.labels.new_roomtypeimage')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
					{!is_new && <Modal.Title className="text-info">{t('licence.labels.edit_roomtypeimage')} {editedElement ? <FontAwesomeIcon icon="save" /> : ''}</Modal.Title>}
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Row>
							<Col>
						{is_new &&
							<ButtonGroup>
								<Button variant={!is_multi ? 'info' : 'outline-info'} onClick={() => setMulti(false)}>
									Singola immagine
								</Button>
								<Button variant={is_multi ? 'info' : 'outline-info'} onClick={() => setMulti(true)}>
									Caricamento Multiplo
								</Button>
							</ButtonGroup>
						}
							</Col>
							<Col className="text-danger">
							{errorMsg}
							</Col>

						</Row>
						<ElementFormDetails element={curElement} t={t} functions={{ edit: onChangeHandler }} is_new={is_new} uploadingState={uploadingState} is_multi={is_multi} previewList={previewList} />
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseRoomTypeImage}>
						{t('modal.close')}
					</Button>
					{is_global ?
						<GlobalSave is_multi={is_multi} isDisabled={isDisabled} is_new={is_new} item_list={item_list} index={index} cb_progress={cb_progress} setParentList={setParentList} editedElement={editedElement} curElement={curElement} setEditedElement={setEditedElement} setShowRoomTypeImageEdit={setShowRoomTypeImageEdit} />
						:
						<DomainSave is_multi={is_multi} isDisabled={isDisabled} is_new={is_new} item_list={item_list} index={index} cb_progress={cb_progress} setParentList={setParentList} editedElement={editedElement} curElement={curElement} setEditedElement={setEditedElement} setShowRoomTypeImageEdit={setShowRoomTypeImageEdit} />
					}
				</Modal.Footer>
			</Modal>
		</>
	)
}



const GlobalSave = ({ editedElement, curElement, setEditedElement, setShowRoomTypeImageEdit, setParentList, index, is_new, cb_progress, isDisabled, is_multi }) => {
	const section = 'globalimage'
	const [is_saving, setIsSaving] = useState(false)
	const handleSaveRoomTypeImage = async () => {
		setIsSaving(true)


		if (is_new) {
			const cb_save = async (url, tracker, index) => {
				let _el = { ...curElement }
				if (index !== undefined) {
					if (url) {
						_el.media = url
						_el.media_tracker = tracker
						_el.description = curElement.description_list[index]
					}
					delete _el.description_list
					delete _el.original_name_list
				} else {
					if (url) {
						_el.media = url
						_el.media_tracker = tracker
					}
				}
				delete _el.file
				const return_data = await addGlobalImage(_el)
				setParentList(p => ([...p, return_data]))
				setEditedElement(false)
				if (!is_multi) {
					setShowRoomTypeImageEdit(false);
				}
			}
			if (curElement.file) {
				const mypath = section
				if (is_multi) {
					for (let f = 0; f < curElement.file.length; f++) {
						const cur_f = curElement.file[f]
						const mediainfo = { description: 'media', source_name: cur_f.name, section: section, item_reference: {} }
						let file_data = uploadFileComplete2(undefined, undefined, mypath, curElement.file[f], curElement.file[f].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save, index: f })
						console.log("FILE", file_data)
					}
				} else {
					const mediainfo = { description: 'media', source_name: curElement.file[0].name, section: section, item_reference: {} }
					let file_data = uploadFileComplete2(undefined, undefined, mypath, curElement.file[0], curElement.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
					console.log("FILE", file_data)
				}
			} else {
				cb_save()
			}
		} else {
			const cb_save = async (url, tracker) => {
				if (url) {
					curElement.media = url
					curElement.media_tracker = tracker
				}
				delete curElement.file
				setParentList(p => {
					let newroomtypeimage_list = [...p]
					const ind = checkComponentPresence (curElement, p, 'Id')
					newroomtypeimage_list.splice(ind, 1, curElement)
					return newroomtypeimage_list
				})
				const return_data = await editGlobalImage(curElement.Id, curElement)
				setEditedElement(false)
				setShowRoomTypeImageEdit(false);
			}
			if (curElement.file) {
				const mypath = section
				const mediainfo = { description: 'media', source_name: curElement.file[0].name, section: section, item_reference: {} }
				console.log(mediainfo, curElement.file[0])
				let file_data = uploadFileComplete2(undefined, undefined, mypath, curElement.file[0], curElement.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
				console.log("FILE", file_data)

			} else {
				cb_save()
			}
		}
	}




	return (
		<Button disabled={isDisabled()} className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveRoomTypeImage}>
			<FontAwesomeIcon icon="save" /> salva (globale) {is_saving &&<Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />}
		</Button>
	)
}




const DomainSave = ({ editedElement, curElement, setEditedElement, setShowRoomTypeImageEdit, setParentList, index, is_new, cb_progress, isDisabled, is_multi }) => {
	const { domain } = useContext(DomainContext)
	const section = 'domainimage'
	const [is_saving, setIsSaving] = useState(false)

	const handleSaveRoomTypeImage = async () => {
		setIsSaving(true)

		if (is_new) {
			const cb_save = async (url, tracker, index) => {
				let _el = { ...curElement }
				if (index !== undefined) {
					if (url) {
						_el.media = url
						_el.media_tracker = tracker
						_el.description = curElement.description_list[index]
					}
					delete _el.description_list
					delete _el.original_name_list
				} else {
					if (url) {
						_el.media = url
						_el.media_tracker = tracker
					}
				}
				delete _el.file
				const return_data = await addGlobalImage(_el)
				setParentList(p => ([...p, return_data]))
				setEditedElement(false)
				if (!is_multi) {
					setShowRoomTypeImageEdit(false);
				}
			}
			if (curElement.file) {
				const mypath = section
				if (is_multi) {
					for (let f = 0; f < curElement.file.length; f++) {
						const cur_f = curElement.file[f]
						const mediainfo = { description: 'media', source_name: cur_f.name, section: section, item_reference: {} }
						let file_data = uploadFileComplete2(domain, undefined, mypath, curElement.file[f], curElement.file[f].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save, index: f })
						console.log("FILE", file_data)
					}
				} else {
					const mediainfo = { description: 'media', source_name: curElement.file[0].name, section: section, item_reference: {} }
					let file_data = uploadFileComplete2(domain, undefined, mypath, curElement.file[0], curElement.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
					console.log("FILE", file_data)
				}
			} else {
				cb_save()
			}
		} else {
			const cb_save = async (url, tracker) => {
				if (url) {
					curElement.media = url
					curElement.media_tracker = tracker
				}
				delete curElement.file
				setParentList(p => {
					let newroomtypeimage_list = [...p]
					const ind = checkComponentPresence (curElement, p, 'Id')
					newroomtypeimage_list.splice(ind, 1, curElement)
					return newroomtypeimage_list
				})
				const return_data = await editGlobalImage(curElement.Id, curElement)
				setEditedElement(false)
				setShowRoomTypeImageEdit(false);
			}
			if (curElement.file) {
				const mypath = section
				const mediainfo = { description: 'media', source_name: curElement.file[0].name, section: section, item_reference: {} }
				console.log(mediainfo, curElement.file[0])
				let file_data = uploadFileComplete2(domain, undefined, mypath, curElement.file[0], curElement.file[0].contentType, mediainfo, { cbProgress: cb_progress, cbSetDownload: cb_save })
				console.log("FILE", file_data)

			} else {
				cb_save()
			}
		}
	}




	return (
		<Button disabled={isDisabled() || is_saving} className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveRoomTypeImage}>
			<FontAwesomeIcon icon="save" /> salva        {is_saving &&<Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />}
		</Button>
	)
}


function ElementFormDetails({ element, functions, ui_list, t, is_new, uploadingState, is_multi, previewList }) {

	const contract_scope_dict = contractScope_dict({ t })


	const updateDescriptionList = (el, index) => {
		const { value } = el
		let _dl = [...element.description_list]
		_dl.splice(index, 1, value)
		functions.edit({ name: 'description_list', value: _dl })
	}

	const unselectAll = () => {
		functions.edit({ name: 'contract_scope_list', value: [] })
	}

	const selectAll = () => {
		console.log()
		functions.edit({ name: 'contract_scope_list', value: Object.values(contract_scope_dict).filter(s => element.contract_type_list.indexOf(s.type) >= 0).map(s => s.id) })
	}

	return (
		<>
			<Form.Group as={Row} controlId="formProductCF" className="border-bottom pb-1">
				<Form.Label column sm="2">immagine </Form.Label>
				<Col>
					<Form.Control type="file" name="file" onChange={(event) => functions.edit(event.target)} multiple={is_multi} />
				</Col>
				<Col>
					{element.media
						?
						<>

							<Image style={{ height: '150px' }} src={element.media} fluid />
							<Button variant="outline-danger" onClick={(event) => functions.edit({ name: 'media', value: '' })}  ><FontAwesomeIcon icon="trash" /></Button>
						</>
						: 
						!is_multi && 
							<Image style={{ height: '150px' }} fluid src={previewList[0]} />

						}
				</Col>
			</Form.Group>
			{!is_multi && uploadingState > 0 ?
				<Row>
					<Col>
						<ProgressBar animated now={uploadingState[0]} label={`${uploadingState[0].toFixed(1)}%`} />
					</Col>
				</Row>
				: <></>}


			{is_multi ? <>
				{element.description_list?.map((i, k) => (
					<>

						<Form.Group as={Row} className="border-bottom pb-1" key={k}>
							<Col sm="1">
								{k + 1}
							</Col>
							<Form.Label column sm="2"><Image style={{ height: '150px' }} fluid src={previewList[k]} /></Form.Label>
							<Col sm="8">
								<Form.Control type="text" name="description" value={i} onChange={(event) => updateDescriptionList(event.target, k)} />
								<Form.Text className="text-muted">
									Descrizione ({element.original_name_list[k]})
								</Form.Text>
							</Col>
						</Form.Group>
						{uploadingState[k]!== undefined && uploadingState[k] > 0 &&
							<Row key={`up${k}`}>
								<Col>
									<ProgressBar animated now={uploadingState[k]} label={`${uploadingState[k].toFixed(1)}%`} />
								</Col>
							</Row>}
					</>

				))}
			</> :
				<Form.Group as={Row} className="border-bottom pb-1">
					<Form.Label column sm="2">{t('global.labels.description')}</Form.Label>
					<Col sm="8">
						<Form.Control type="text" name="description" value={element.description} onChange={(event) => functions.edit(event.target)} />
					</Col>
				</Form.Group>
			}
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.type')}</Form.Label>
				<Col>
					<Form.Check type="checkbox" name="contract_type_list" value="hospital" checked={element.contract_type_list.indexOf('hospital') >= 0} label={t('contract.labels.type_hospital')} onChange={(event) => functions.edit(event.currentTarget)} />
					<Form.Check type="checkbox" name="contract_type_list" value="civil" checked={element.contract_type_list.indexOf('civil') >= 0} label={t('contract.labels.type_civil')} onChange={(event) => functions.edit(event.currentTarget)} />
				</Col>
			</Form.Group>
			<Form.Group as={Row} className="border-bottom">
				<Form.Label column sm="2">{t('global.labels.scope')}</Form.Label>
				<Col>
					<ButtonGroup size="sm">
						<Button variant="outline-info" onClick={selectAll}>Seleziona tutto</Button>
						<Button variant="outline-info" onClick={unselectAll}>Deseleziona tutto</Button>

					</ButtonGroup>
					{Object.values(contract_scope_dict).map((s, sk) => (
						<Form.Check type="checkbox" name="contract_scope_list" value={s.id} disabled={element.contract_type_list.indexOf(s.type) < 0} checked={element.contract_scope_list.indexOf(s.id) >= 0} label={s.label} onChange={(event) => functions.edit(event.currentTarget)} />
					))}
				</Col>
			</Form.Group>
		</>
	)
}


export default translate()(ModalRoomTypeImage)