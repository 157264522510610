import React, { useState, useContext, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Row, Col,  Button, Spinner, Image } from 'react-bootstrap';
import GlobalDataContext from '../contexts/globaldataContext.js';
import DomainContext from '../../components/contexts/domainContext'
import {InitContractHelper} from "../../util/contract"
import {getElementPreview} from "../../util/ui_utils"
import { useHistory } from "react-router-dom";

let load_contract = false
let loaded_auto = false



export const ModalLoadOneContract = ({ element, t, preload_contract }) => {

    const { contract} = useContext(GlobalDataContext)
	const { domain, domain_data } = useContext(DomainContext)
    const {initContract} = InitContractHelper()
    const history = useHistory()

    load_contract = preload_contract || false
    console.log("ModalLoadOneContract", preload_contract, load_contract)
    const [loadingOne, setLoadingOne] = useState(false)

    const handleOpenContract = async () => {
        const el = element
        const curId = el.id
        loaded_auto = false
        setLoadingOne(true)
        const ret_data = await initContract(curId)
        console.log("INIT CONTRACT DATA RETURN", load_contract, ret_data, loaded_auto)
            history.push(ret_data.id+"/home");
        load_contract= true
            console.log("GO MANUAL")
        //}

        /*         setInterval(function () {
                    if (sessionStorage.getItem('contractId') === curId) {
                        setTimeout(function () {
                            history.push("/home");
                        }, 2000)
                    }
                }, 2000) */
    }


    return (
        <>
            <Button className="me-1" variant="success" size="sm" onClick={() => handleOpenContract()}><FontAwesomeIcon icon="folder-open" /> Apri</Button>
            <Modal show={loadingOne} size="md" backdrop="static" className="bg-secondary" centered>
                <Modal.Body >
                    <Container fluid className="animatedBox"
                    >
                        <Row className="mb-2 pb-2 border-bottom">
                            <Col className="text-center">
									<Image 
										src={domain !== 'admin' && (domain_data && domain_data.logo) ? domain_data.logo : "./Logo.Claudit.registrato.png"}
										style={{maxHeight:'80px', maxWidth:'200px', minHeight: '30px'}}
									/>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col className="text-center">
                            {getElementPreview(element)}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                Caricamento in corso
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <b>{element.name}</b>
                            </Col>
                        </Row>
{/*                         <Row>
                            <Col className="text-center">
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </Col>
                        </Row> */}

                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}

const contract_scope_icons = {
    "hospital": "./icone_contratto/ospedale.png",
    "retirement": "./icone_contratto/casariposo.png",
    "clinic": "./icone_contratto/poliambulatorio.png",
    "community": "./icone_contratto/comunita.png",
    "school": "./icone_contratto/scuola.png",
    "office": "./icone_contratto/ufficio.png",
    "shopping_center": "./icone_contratto/centrocommerciale.png",
    "bank": "./icone_contratto/banca.png",
    "railroad": "./icone_contratto/ferrovia.png",
    "highway": "./icone_contratto/autostrada.png",
    "airport": "./icone_contratto/aeroporto.png",
    "hotel": "./icone_contratto/hotel.png",
    "catering": "./icone_contratto/ristorante.png",
    "residential": "./icone_contratto/residenziale.png",
    "prison": "./icone_contratto/carcere.png",
}

const getContractDescriptionOld = (i, t) => {
    if (contract_scope_icons[i.scope]) {
        return (
            <>
                <img height="20" src={contract_scope_icons[i.scope]} />
                &nbsp;
                {t('contract.labels.type_' + i.type)}
                &nbsp;
                {t('contract.labels.scope_' + i.sector)}
                &nbsp;
                <i>({t('contract.labels.sector_' + i.scope)})</i>
            </>
        )
    }

}

export const getContractDescription = (i, t) => {
    if (contract_scope_icons[i.scope]) {
        return (
            <>
                <img height="20" src={contract_scope_icons[i.scope]} />
                &nbsp;
                {t('contract.labels.type_' + i.type)}
                &nbsp;
                {t('contract.labels.scope_' + i.sector)}
                &nbsp;
                <i>({t('contract.labels.sector_' + i.scope)})</i>
            </>
        )
    }

}