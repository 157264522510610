import axios from 'axios';
import { GFUNC_DOMAIN } from '../constants/cloudfunctionparams'
import { auth } from '../../Firebase2/firebaseIndex';

///////////////////////////////////////////////////////////////////////////////
//
//	ricerca l'email nel dominio e nel sistema di autentificazione
//	nel dizionario restituito:
//			uid: 			uid
//			displayName:	displayName
//  		result:			'user-found-in-domain' | 'user-found-in-system' | 'user-not-found'
//					
export const getUserByEmail = async (email: string, domainId: string) => {
	//const authToken = localStorage.getItem('AuthToken');
	const authToken = auth.currentUser ? await auth.currentUser.getIdToken(/* forceRefresh */ true) : null
	console.log("authToken: ", authToken)
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
	console.log("EMAIL", email)

	const data = {
		headers: { Authorization: `Bearer ${authToken}` },
		params : {
			email: email,
			domainId: domainId
		}
	  };


	return axios
		.get(`${GFUNC_DOMAIN}/getUserByEmail`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
					console.log(error.response.data)
				} else {
					console.log("unknown error", error)
				}
			}
			return { error: error };
		});
}



///////////////////////////////////////////////////////////////////////////////
//
//	restituisce la lista degli utenti (da USERDOMAINSUMMARY)
//  dizionario per ogni utente:
//		"uid": "",
//		"email": "",
//		"displayName": "",
//		"domainArray": []
//
export const getAllUserList = async () => {
	//const authToken = localStorage.getItem('AuthToken');
	const authToken = auth.currentUser ? await auth.currentUser.getIdToken(/* forceRefresh */ true) : null
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
const data = {
		headers: { Authorization: `Bearer ${authToken}` },
}
	return axios
		.get(`${GFUNC_DOMAIN}/getAllUserList`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
				} else {
					console.log("unknown error", error)
				}
			}
			return { error: error };
		});
}




///////////////////////////////////////////////////////////////////////////////
//
//	displayName, phoneNumber e photoURL possono essere undefined se si vuole evitare di cambiarli TODO: VERIFICARE!!!
//
export const updateUserData = async (uid: string, displayName: string, phoneNumber: any, photoURL: any) => {
	const data = {
		uid: uid,
		phoneNumber: phoneNumber,
		displayName: displayName,
		photoURL: photoURL,
	}
	//const authToken = localStorage.getItem('AuthToken');
	const authToken = auth.currentUser ? await auth.currentUser.getIdToken(/* forceRefresh */ true) : null
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };


	return axios
		.post(`${GFUNC_DOMAIN}/updateUserData`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
				} else {
					console.log("unknown error", error)
				}
			}
			return { error: error };
		});
}


///////////////////////////////////////////////////////////////////////////////
//
//	crea un nuovo utente e lo aggiunge al dominio
//
export const createDomainUser = async (
	email: string,
	password: string,
	confirmPassword: string,
	displayName: string,
	domainId: string,

	systemgroups: any,
	contractgroups: any,

	phoneNumber: any,
	photoURL: any) => {


	const data = {
		email: email,
		password: password,
		confirmPassword: confirmPassword,

		systemgroups: systemgroups,
		contractgroups: contractgroups,

		domainId: domainId,
		phoneNumber: phoneNumber,
		displayName: displayName,
		photoURL: photoURL,
	}
	//const authToken = localStorage.getItem('AuthToken');
	const authToken = auth.currentUser ? await auth.currentUser.getIdToken(/* forceRefresh */ true) : null
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
	//const headers =  { Authorization: `Bearer ${authToken}` }

	return axios
		.post(`${GFUNC_DOMAIN}/createDomainUser`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
				} else {
					console.log("unknown error", error)
				}
			}
			return { error: error };
		});
}


///////////////////////////////////////////////////////////////////////////////
//
//	aggiunge uno user già presente nel sistem al dominio
//
export const addUserToDomain = async (uid: string,
	domainId: string,
	systemgroups: any,
	contractgroups: any,
	displayName: string,
	email: string) => {
	
	const data = {
		uid: uid,
		systemgroups: systemgroups,
		contractgroups: contractgroups,
		domainId: domainId,
		displayName: displayName,
		email: email,
	}
	//const authToken = localStorage.getItem('AuthToken');
	const authToken = auth.currentUser ? await auth.currentUser.getIdToken(/* forceRefresh */ true) : null
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
	const headers =  { Authorization: `Bearer ${authToken}` }

	return axios
		.post(`${GFUNC_DOMAIN}/addUserToDomain`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
				} else {
					console.log("unknown error", error)
				}
			}
			return { error: error };
		});
}






///////////////////////////////////////////////////////////////////////////////
//
//	rimuove un utente dal dominio (ma non dal sistema)
//
export const removeUserFromDomain = async (uid: string, domainId: string,) => {

	const data = {
		uid: uid,
		domainId: domainId,
	}
	//const authToken = localStorage.getItem('AuthToken');
	const authToken = auth.currentUser ? await auth.currentUser.getIdToken(/* forceRefresh */ true) : null
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };

	return axios
		.post(`${GFUNC_DOMAIN}/removeUserFromDomain`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
				} else {
					console.log("unknown error", error)
				}
			}
			return { error: error };
		});
}

///////////////////////////////////////////////////////////////////////////////
//
//	rimuove un utente dal sommario di tutti i domini (ma non dal sistema)
//
export const removeUserFromAllDomain = async (uid: string) => {

	const data = {
		uid: uid,
	}
	//const authToken = localStorage.getItem('AuthToken');
	const authToken = auth.currentUser ? await auth.currentUser.getIdToken(/* forceRefresh */ true) : null
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };

	return axios
		.post(`${GFUNC_DOMAIN}/removeUserFromAllDomain`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
				} else {
					console.log("unknown error", error)
				}
			}
			return { error: error };
		});
}



///////////////////////////////////////////////////////////////////////////////
//
//	disabilita (o riabilita) un utente dall'autentificazione generale
//
export const disableUser = async (uid: string, disable: boolean) => {

	const data = {
		uid: uid,
		disable: disable
	}
	//const authToken = localStorage.getItem('AuthToken');
	const authToken = auth.currentUser ? await auth.currentUser.getIdToken(/* forceRefresh */ true) : null
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };

	return axios
		.post(`${GFUNC_DOMAIN}/disableUser`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
				} else {
					console.log("unknown error", error)
				}
			}
			return { error: error };
		});
}






///////////////////////////////////////////////////////////////////////////////
//
//	resetta la password dell'utente
//
export const resetUserCredential = async (uid: string,
	newPassword: string) => {

	const data = {
		uid: uid,
		newPassword: newPassword
	}
	//const authToken = localStorage.getItem('AuthToken');
	const authToken = auth.currentUser ? await auth.currentUser.getIdToken(/* forceRefresh */ true) : null
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };

	return axios
		.post(`${GFUNC_DOMAIN}/resetUserCredential`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
				} else {
					console.log("unknown error", error)
				}
			}
			return { error: error };
		});
}