import { firestore } from '../firebaseIndex'

const addRoom = async (domainId, contractId, structureId, pavillonId, floorId, departmentId, data) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId)
            .collection('rooms')
            .add(data);

        const newdata = {
            ...data,
            id: res.id,
        }

        // === Light Version ===
        const lightdata = {
            id: newdata.id,
            ...data.glass_percentage && {glass_percentage: data.glass_percentage},
            ...data.mq && {mq: data.mq},
            ...data.name && {name: data.name},
            ...data.level && {name: data.level},
            ...data.parent_list && {parent_list: data.parent_list},
            ...data.risk_area && {risk_area: data.risk_area},
            ...data.roomtype && {roomtype: data.roomtype},
            ...data.size && {size: data.size},
            ...data.status && {status: data.status},
        }

        console.log("LIGHTROOM ", domainId, contractId)
        const res2 = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId)
            .collection('lightrooms')
            .doc(res.id)
            .set(lightdata);


        return newdata
    } catch (e) {
        console.log("addRoom error: ", e)
        return { error: e }
    }
}

const getRoomById = async (domainId, contractId, structureId, pavillonId, floorId, departmentId, roomId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId)
            .collection('rooms')
            .doc(roomId)
            .get()

        const firebaseData = res.data()
        const data = {
            ...firebaseData,
            id: res.id,
        }
        return data;
    } catch (e) {
        console.log("getDepartment error: ", e)
        return { error: e }
    }
}

const getRoomList = async (domainId, contractId, structureId, pavillonId, floorId, departmentId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId)
            .collection('rooms')
            .get();

        const roomList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return roomList;
    } catch (e) {
        console.log("getRoomList error: ", e)
        return { error: e }
    }
}



export const getLightRoomList = async (domainId, contractId, structureId, pavillonId, floorId, departmentId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId)
            .collection('lightrooms')
            .get();

        const roomList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return roomList;
    } catch (e) {
        console.log("getLightRoomList error: ", e)
        return { error: e }
    }
}





const getAllRoomList = async ({ structureId, pavillonId, floorId, departmentId }) => {
    try {
        let res = firestore.collectionGroup('rooms')
        if (structureId !== undefined) {
            res = res.where("parent_list", "array-contains", structureId)
        }
        if (pavillonId !== undefined) {
            res = res.where("parent_list", "array-contains", pavillonId)
        }
        if (floorId !== undefined) {
            res = res.where("parent_list", "array-contains", floorId)
        }
        if (departmentId !== undefined) {
            res = res.where("parent_list", "array-contains", departmentId)
        }

        console.log("getAllRoomList: pointA (", structureId, ", ", pavillonId, ", ", floorId, ", ", departmentId, ")")
        res = await res.get()
        console.log("getAllRoomList: pointB")

        const roomList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            //            console.log("getAllRoomList -> room path: ", doc.ref.path)
            return data
        })
        return roomList;
    } catch (e) {
        console.log("getRoomList error: ", e)
        return { error: e }
    }
}




export const getAllRoomListStatus = async ({ structureId, pavillonId, floorId, departmentId, status }) => {
    try {
        let res = firestore.collectionGroup('rooms')
        if (structureId !== undefined) {
            res = res.where("parent_list", "array-contains", structureId)
        }
        if (pavillonId !== undefined) {
            res = res.where("parent_list", "array-contains", pavillonId)
        }
        if (floorId !== undefined) {
            res = res.where("parent_list", "array-contains", floorId)
        }
        if (departmentId !== undefined) {
            res = res.where("parent_list", "array-contains", departmentId)
        }
        res = res.where("status", "==", status)

        res = await res.get()

        const roomList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return roomList;
    } catch (e) {
        console.log("getAllRoomListStatus error: ", e)
        return { error: e }
    }
}


export const getAllLightRoomList = async ({ structureId, pavillonId, floorId, departmentId, status }) => {
    try {
        let res = firestore.collectionGroup('lightrooms')
        if (structureId !== undefined) {
            res = res.where("parent_list", "array-contains", structureId)
        }
        if (pavillonId !== undefined) {
            res = res.where("parent_list", "array-contains", pavillonId)
        }
        if (floorId !== undefined) {
            res = res.where("parent_list", "array-contains", floorId)
        }
        if (departmentId !== undefined) {
            res = res.where("parent_list", "array-contains", departmentId)
        }
        if (status !== undefined) {
            res = res.where("status", "==", status)
        }


        res = await res.get()

        const roomList = res.docs.map(doc => {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }
            return data
        })
        return roomList;
    } catch (e) {
        console.log("getAllLightRoomList error: ", e)
        return { error: e }
    }
}




const editRoom = async (domainId, contractId, structureId, pavillonId, floorId, departmentId, roomId, data) => {
    try {
        const room = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId)
            .collection('rooms')
            .doc(roomId);

        const res = await room.set(
            data
        );


        // === Light Version ===
        const lightdata = {
            id: roomId,

            ...data.glass_percentage && {glass_percentage: data.glass_percentage},
            ...data.mq && {mq: data.mq},
            ...data.name && {name: data.name},
            ...data.level && {name: data.level},
            ...data.parent_list && {parent_list: data.parent_list},
            ...data.risk_area && {risk_area: data.risk_area},
            ...data.roomtype && {roomtype: data.roomtype},
            ...data.size && {size: data.size},
            ...data.status && {status: data.status},

        }

        const res2 = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId)
            .collection('lightrooms')
            .doc(roomId)
            .set(lightdata);



        return res
    } catch (e) {
        console.log("editRoom error: ", e)
        return { error: e }
    }
}


const deleteRoom = async (domainId, contractId, structureId, pavillonId, floorId, departmentId, roomId) => {
    try {
        const res = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId)
            .collection('rooms')
            .doc(roomId).delete();

        
        // Versione Light
        const res2 = await firestore.collection('DOMAINS')
            .doc(domainId)
            .collection('contracts')
            .doc(contractId)
            .collection('baseCompany')
            .doc('BASE')
            .collection('structures')
            .doc(structureId)
            .collection('pavillons')
            .doc(pavillonId)
            .collection('floors')
            .doc(floorId)
            .collection('departments')
            .doc(departmentId)
            .collection('lightrooms')
            .doc(roomId).delete();

        return res
    } catch (e) {
        console.log("deleteRoom error: ", e)
        return { error: e }
    }
}



// =====================  Process roomlist for migration ================
export const cretateLightRoomList = async ({domainId, contractId, structureId }) => {
    try {
        console.log("createLightRoomList!!!")

        let res = firestore.collectionGroup('rooms')
        if (structureId !== undefined) {
            res = res.where("parent_list", "array-contains", structureId)
        }
        

        res = await res.get()
        
        let count = 0;
        for (const doc of res.docs) {
            const firebaseData = doc.data()
            const data = {
                ...firebaseData,
                id: doc.id,
            }


            // === Light Version ===
            const lightdata = {
                id: data.id,
                ...data.glass_percentage && {glass_percentage: data.glass_percentage},
                ...data.mq && {mq: data.mq},
                ...data.name && {name: data.name},
                ...data.parent_list && {parent_list: data.parent_list},
                ...data.risk_area && {risk_area: data.risk_area},
                ...data.roomtype && {roomtype: data.roomtype},
                ...data.size && {size: data.size},
                ...data.status && {status: data.status},
            }

            const res2 = await firestore.collection('DOMAINS')
                .doc(domainId)
                .collection('contracts')
                .doc(contractId)
                .collection('baseCompany')
                .doc('BASE')
                .collection('structures')
                .doc(data.parent_list[0])
                .collection('pavillons')
                .doc(data.parent_list[1])
                .collection('floors')
                .doc(data.parent_list[2])
                .collection('departments')
                .doc(data.parent_list[3])
                .collection('lightrooms')
                .doc(data.id)
                .set(lightdata);

            
            count = count + 1
            console.log("[", count, "], domain: ", domainId, ", contract: ", contractId, " structure: ", data.parent_list[0], " room id:", data.id)
        }
        return count;
    } catch (e) {
        console.log("cretateLightRoomList error: ", e)
        return { error: e }
    }
}


export { addRoom };
export { getRoomById };
export { getRoomList };
export { getAllRoomList };
export { editRoom };
export { deleteRoom };