import React, { createContext, useState, useEffect, useRef } from 'react'

const InterventionContext = createContext()

export const InterventionProvider = (props) => {
    const [intervention_dict, setInterventionDict] = useState({})
    const [update_hour, setUpdateHour] = useState(false)
    const [secondary_service_list, setSecondaryServiceList] = useState([])
    const [ui_list, setUiList] = useState({})

    const intervention_dict_ref = useRef({})

    const updateInterventionDict = (i_id, intervention) => {
        setInterventionDict((prev) => ({ ...prev, [i_id]: intervention }))
    }

    return (

        <InterventionContext.Provider
            value={{
                intervention_dict_ref,
                intervention_dict, setInterventionDict, updateInterventionDict,
                secondary_service_list, setSecondaryServiceList,
                update_hour, setUpdateHour,
                ui_list, setUiList
            }}>
            {props.children}

        </InterventionContext.Provider>
    )
}
export default InterventionContext