import React, { useState, useEffect, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Badge, Container, ButtonGroup, Row, Col, Form, Button, Tab, Nav, OverlayTrigger, Spinner, Tooltip, Card } from 'react-bootstrap';
import { translate } from 'react-polyglot'
import { useParams } from "react-router-dom"
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import 'moment/locale/it';
import 'moment/locale/de';

import { processCheckSection_dict, materialIntendedUse_dict, timingMode_dict, processcontroltiming_dict } from "../components/constants/global_definitions"
import styles from '../components/constants/styles.js'


import LocaleContext from "../components/contexts/locale.js"
import DomainContext from '../components/contexts/domainContext'
import GlobalDataContext from './contexts/globaldataContext.js';
import { InitContractHelper } from "../util/contract"
//import EditContext, { EditProvider } from "../components/contexts/edit.js"


import { ItemProgress, ServiceBadge, ContainerInfoPopover, ResultBadge } from '../components/StructureGraphicElements.js'
import { checkComponentPresence, rgba2hex, getTimeValue, compareName, getValue, getSelectedElement, compareOrder, makeid, getElementPreview, getFixedNumber, getBaseObjectValue } from "../util/ui_utils.js"


import AlertSection from "../components/AlertSection.js"
import DeleteConfirmationSection from "../components/DeleteConfirmationSection.js"
import PageTitle from "../components/PageTitle"
import { SearchSection } from "../components/SearchSection"
import Footer from "../components/Footer"
import { ElementFormNoncomplianceAction } from "../components/ElementFormNoncomplianceAction"



import { editIntervention as editIntervention2, getInterventionById as getInterventionById2, deleteIntervention as deleteIntervention2 } from '../Firebase2/Storage2/dbIntervention'
import { editWorkarea as editWorkarea2, getWorkareaList as getWorkareaList2 } from '../Firebase2/Storage2/dbWorkarea';
//FIXME: recupero name!!!

import { getProductList as getProductList2 } from '../Firebase2/Storage2/dbWarehouse'
import { getDpiList as getDpiList2 } from '../Firebase2/Storage2/dbDpi'
import {
	getEconomicalMaterialList as getEconomicalMaterialList2
} from '../Firebase2/Storage2/dbRoomMaterial';
import { getProcessQuestionList as getProcessQuestionList2, } from "../Firebase2/Storage2/dbChecks"





//const contractId = sessionStorage.getItem('contractId')

/* let contract = {}
try {
	contract = JSON.parse(sessionStorage.getItem('contract'))
} catch (err) { } 
console.log(contract)

*/






const companyId = "0"

let intervention_dict = {}
//let structure_list = []



const defaultfilter = { service: [] }






////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//
////////////////////////////////////////////////////////////////////////////////////////////////////////
const ProcessCheck = ({ t, uData, canWrite }) => {
	let load_page = false
	const intervention_type = 'ordinary'
	const { domain  } = useContext(DomainContext)
	const { contract, structureElements, roomtype_dict, risk_area_list, risk_area_dict, service_dict, operation_dict } = useContext(GlobalDataContext)
	//	const [roomList, setRoomList] = useContext(RoomContext)

	let structure_list = structureElements.structure_list
	const { initContract } = InitContractHelper()
	let { cid } = useParams();
	let contractId = cid


	const childRefAlert = useRef()
	const childRefDelete = useRef()


	//	const [structure_list, setStructureList] = useState([])
	const [ui_list, setUiList] = useState({})
	const [workarea_list, setWorkareaList] = useState([])
	//	const [intervention_dict, setInterventionDict] = useState({})
	const [loading, setLoading] = useState(true)
	const [intervention_count, setInterventionCount] = useState(0)
	const [filterAppliedList, setFilterAppliedList] = useState(defaultfilter)
	const [searchTerm, setSearchTerm] = useState('')


	const initValues = async () => {
		const userData = uData
		setLoading(true)
		const pavillon_dict = structureElements.pavillon_dict
		const department_dict = structureElements.department_dict
		const floor_dict = structureElements.floor_dict
		/* 		const pavillon_list = await getAllPavillonName()
				const floor_list = await getAllFloorName()
				const department_list = await getAllDepartmentName() */
		console.log(contract, contractId)
		if (contract && domain.length > 0) {
			load_page = true
			contractId = contract.id
			structure_list = structureElements.structure_list
			let permission_department_list = []
			let permission_structure_list = []
			if (userData && userData.contract_dict) {
				console.log('userData contract', userData.contract_dict[contractId])
				if (userData.contract_dict[contractId] && userData.contract_dict[contractId][0].structure) {
					if (userData.contract_dict[contractId][0].department && userData.contract_dict[contractId][0].department.id) {
						permission_department_list = userData.contract_dict[contractId].map(cl => cl.department.id)
						console.log("permission department_list", permission_department_list, userData.contract_dict[contractId][0].department)
					}
					if (userData.contract_dict[contractId][0].structure && userData.contract_dict[contractId][0].structure.id) {
						permission_structure_list = userData.contract_dict[contractId].map(cl => cl.structure.id)
						structure_list = structure_list.filter(s => permission_structure_list.indexOf(s.id) !== -1)

					}
					console.log("permission structure_list", permission_structure_list)
				}
			}
			let global_question_list = await getProcessQuestionList2(domain, contractId)

			//cart element
			const neweconomic_material_list = await getEconomicalMaterialList2(domain, contractId)
			let newelementp_list = await getProductList2(domain, contractId, companyId, 'product', null)
			for (let p = 0; p < newelementp_list.length; p++) {
				newelementp_list[p].category_type = 'product'
			}
			let newelemente_list = await getProductList2(domain, contractId, companyId, 'equipment', null)
			for (let e = 0; e < newelemente_list.length; e++) {
				newelemente_list[e].category_type = 'equipment'
			}
			let newelementc_list = await getProductList2(domain, contractId, companyId, 'cart', null)
			for (let c = 0; c < newelementc_list.length; c++) {
				newelementc_list[c].category_type = 'cart'
			}
			let newelementm_list = await getProductList2(domain, contractId, companyId, 'machine', null)
			for (let m = 0; m < newelementm_list.length; m++) {
				newelementm_list[m].category_type = 'machine'
			}
			let newelementcm_list = await getProductList2(domain, contractId, companyId, 'cleaningmaterial', null)
			for (let cm = 0; cm < newelementcm_list.length; cm++) {
				newelementcm_list[cm].category_type = 'cleaningmaterial'
			}
			let newelementd_list = await getDpiList2(domain, contractId)
			newelementd_list.map(d => d.category_type = 'dpi')
			const newgood_list = [
				...newelementp_list,
				...newelemente_list,
				...newelementc_list,
				...newelementm_list,
				...newelementcm_list,
				...newelementd_list,
			]
			newgood_list.sort(compareName)
			setUiList({ ...ui_list, pavillon_dict, department_dict, floor_dict, risk_area_list, risk_area_dict, operation_dict, roomtype_dict, service_dict, good_list: newgood_list, economic_material_list: neweconomic_material_list, global_question_list })
			let db_workarea_list = await getWorkareaList2(domain, contractId)
			let tintervention_count = 0
			if (intervention_type === 'ordinary') {
				for (const w of db_workarea_list) {
					if (w.intervention_list) {
						tintervention_count += w.intervention_list.length
					}
				}

			} else {
				for (const w of db_workarea_list) {
					if (w.periodicintervention_list) {
						tintervention_count += w.periodicintervention_list.length
					}
				}

			}

			db_workarea_list = db_workarea_list.filter(w => filterPermissionData(w, permission_structure_list, permission_department_list)).filter(w => (w.service_summary && w.service_summary.filter(s => s.type === intervention_type).length > 0))
			db_workarea_list.sort(compareName)
			setInterventionCount(tintervention_count)
			//			setInterventionDict(intervention_dict)
			setWorkareaList(db_workarea_list)
		} else {
			console.log("init data")

		}
		setLoading(false)
	}




	const updateWorkarea = (w) => {
		let editworkareas = [...workarea_list]
		const cw = checkComponentPresence(w, workarea_list, 'id')
		editworkareas.splice(cw, 1, w)
		setWorkareaList(editworkareas)

	}




	useEffect(() => {
		async function loadData() {
			let contractId = cid
			console.log("INTERVENTION reinit contract from param", contractId)
			if (contractId && contractId !== null) {
				const ret_data = await initContract(contractId)
				console.log("INTERVENTION init contract return", ret_data)
			}

		}
		if (!contract) {
			loadData()

		}
	}, [])




	useEffect(() => {
		console.log('INTERVENTION loadingcontract', contract)
		if (contract && !load_page) {
			initValues()
		}
	}, [contract])










	return (
		<>

			<AlertSection ref={childRefAlert} />
			<DeleteConfirmationSection ref={childRefDelete} />
			<Container fluid>
				<Row>
					<Col className="h5 text-primary">
						{t('checks.labels.params')}
					</Col>
					{!loading ?
						<>
							<Col className="text-center">
								<ModalFilter t={t} ui_list={ui_list} filterAppliedList={filterAppliedList} setFilterAppliedList={setFilterAppliedList} defaultfilter={defaultfilter} intervention_type={intervention_type} />
							</Col>
							<Col className="text-center">
								<SearchSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
							</Col>
						</> : ''}
				</Row>
				{workarea_list && workarea_list.length > 0 ?
					<Row className="p-1 text-light">
						<Col className="bg-secondary ms-1" >{t('workareas.labels.name')}</Col>
						<Col className="bg-secondary ms-1" >{t('global.labels.service')}</Col>
						{/* 									<Col className="bg-secondary ms-1 text-center p-0" sm="2" >{t('workareas.labels.frequency')}
										<br />
										{t('workarea.labels.operating_units')}
									</Col> */}
						<Col className="bg-secondary ms-1 text-center p-0" sm="1" >m<sup>2</sup></Col>
						<Col className="bg-secondary ms-1 text-center" sm="2">
							{contract.type === 'civil' ?
								t('workareas.labels.standard_cleaning')
								:
								t('workareas.labels.risk_area')

							}</Col>
						<Col className="bg-secondary ms-1 text-center" sm="2" >{t('workareas.labels.controls')}</Col>
						<Col className="bg-secondary ms-1 text-center" sm="2">Azioni</Col>
					</Row>
					: ''}
			</Container>
			<Container fluid>
				{loading ?
					<Row>
						<Col className="text-center">
							<Spinner animation="border" role="status" />&nbsp;Caricamento in corso
						</Col>
					</Row>
					:
					<>
						{workarea_list.length > 0 ?
							<>
								{workarea_list
									.filter(i => (filterAppliedList.service.length === 0 ||
										checkWorkareaInterventionServiceFilter(i, filterAppliedList, intervention_dict, intervention_type)
									))
									.filter(i => (searchTerm.length === 0 || (i.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)))
									.map((w, key) => (

										<Row key={key} className="p-1 border rounded mb-1 one_workarea border-secondary">
											<Col>
												<Row className="mb-1">
													<Col>
														<b>{w.name}</b> &nbsp;
														<Badge bg="secondary" className="me-1">{w.room_list.length} {t('global.labels.rooms')}</Badge>
														{w.intervention_list && w.intervention_list.filter(f => typeof f === 'string').length > 0 ?
															<Badge bg="secondary" disabled>{w.intervention_list.filter(f => typeof f === 'string').length} {t('global.labels.interventions')}</Badge>
															: ''
														}
													</Col>
													<Col sm="1" className="text-center">{getFixedNumber(w.size)}</Col>
													<Col sm="2">
														{w.risk_area_summary && w.room_list.length > 0 ? <ItemProgress ui_list={ui_list} risk_list={w.risk_area_summary} item={w} mode='mq' /> : ''}
													</Col>
													<Col sm="2"></Col>
													<Col sm="2 p-0 text-center">
													</Col>
												</Row>

												<div>
													{w.intervention_list && w.intervention_list.filter(f => typeof f === 'string').length > 0
														?
														<>
															<Row className="p-1 bglight section_title">
																<Col className="ms-1 bg-light text-secondary" >{t('workareas.labels.intervention_list')}</Col>
															</Row>
															{w.intervention_list.filter(f => typeof f === 'string')
																.filter(i => (filterAppliedList.service.length === 0 ||
																	checkInterventionServiceFilter(intervention_dict[i], filterAppliedList)
																))
																.map((intervention_id, ki) => (
																	<Row key={ki} className="p-1 bg-light">
																		<OneIntervention intervention_id={intervention_id} ki={ki} workarea={w} ui_list={ui_list} updateWorkarea={updateWorkarea} canWrite={canWrite} t={t} childRefAlert={childRefAlert} />
																	</Row>
																))}
														</>
														:
														<i>
															{t('workeareas.messages.no_intervention')}
														</i>
													}
												</div>
											</Col>
										</Row>
									))}
							</>
							: <Row>
								<Col className="font-italic">
									{t('workeareas.messages.no_workearea')}
								</Col>
							</Row>
						}







					</>
				}
			</Container>
		</>
	);
}



/// footer helper function
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function getElementCount(int_dict, filterList, t, int_count) {
	const ik = Object.keys(int_dict)
	const filt = ik.reduce(function (filtered, d) {
		if (int_dict[d].service && int_dict[d].service.id && filterList.indexOf(int_dict[d].service.id) !== -1) {
			filtered.push(int_dict[d])
		}
		return filtered
	}, [])
	if (filterList.length === 0 || filt.length === ik.length) {
		return (<>{int_count} {t('global.labels.interventions')}</>)
	} else {
		return (<>{filt.length}/{ik.length} {t('global.labels.interventions')}</>)
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkWorkareaInterventionServiceFilter(w, filterAppliedList, intervention_dict, intervention_type) {
	let ispresent = false

	//	console.log("interventiontype", intervention_type)
	if (intervention_type === 'ordinary') {
		if (w.intervention_list && w.intervention_list.filter(f => typeof f === 'string').length > 0 && intervention_dict) {
			w.intervention_list.filter(f => typeof f === 'string').map(f => {
				if (intervention_dict[f].service && intervention_dict[f].service.id && filterAppliedList.service.indexOf(intervention_dict[f].service.id) !== -1) {
					ispresent = true
				}
			})
		}

	} else {
		if (w.periodicintervention_list && w.periodicintervention_list.filter(f => typeof f === 'string').length > 0 && intervention_dict) {
			w.periodicintervention_list.filter(f => typeof f === 'string').map(f => {
				console.log(intervention_dict, f)
				if (intervention_dict[f].service && intervention_dict[f].service.id && filterAppliedList.service.indexOf(intervention_dict[f].service.id) !== -1) {
					ispresent = true
				}
			})
		}

	}
	return ispresent
}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkInterventionServiceFilter(i, filterAppliedList) {
	let ispresent = false
	if (i.service && i.service.id && filterAppliedList.service.indexOf(i.service.id) !== -1) {
		ispresent = true
	}
	return ispresent
}




////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function filterPermissionData(w, structure_list, department_list) {
	let ispermitted = false
	if (structure_list.length === 0) {
		ispermitted = true
	} else {
		if (department_list.length > 0) {
			if (w.department_list.filter(e => department_list.indexOf(e) !== -1).length > 0) {
				ispermitted = true
			}

		} else {
			if (w.structure_list.filter(e => structure_list.indexOf(e) !== -1).length > 0) {
				ispermitted = true
			}

		}
	}
	return ispermitted

}


////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const toNumbers = arr => arr.map(Number)







////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const renderCheckTooltip = (props) => (
	<Tooltip id="button-tooltip" {...props}>
		Operazione con controllo di processo obbligatorio
	</Tooltip>
);



////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneIntervention = ({ intervention_id, ki, workarea, ui_list, updateWorkarea, canWrite, t, childRefAlert }) => {

	const { domain } = useContext(DomainContext)
	const { contractId } = useContext(GlobalDataContext)
	const [i, setIntervention] = useState()
	const [loading, setLoading] = useState(false)


	const handleUpdateIntervention = (i) => {
		console.log("new lqa", i.lqa)
		intervention_dict[i.id] = i
		setIntervention(i)
	}
	useEffect(() => {

		async function fetchData() {
			setLoading(true)

			let intervention = intervention_dict[intervention_id]

			if (!intervention) {
				if (typeof intervention_id === 'string') {
					intervention = await getInterventionById2(domain, contractId, intervention_id)
					console.log(intervention)
					intervention_dict[intervention_id] = intervention
					setIntervention(intervention)
				}
			} else {
				setIntervention(intervention)
			}
			setLoading(false)
		}
		fetchData()
	}
		, [])

	const process_check_section = processCheckSection_dict({ t })


	if (i) {
		return (
			<Col>
				<Row className="border-bottom pb-1">
					<Col>
						<FontAwesomeIcon icon="circle" className="text-secondary" />
						&nbsp;
						<b>{i.name}</b><br />
						<ContainerInfoPopover w={i} t={t} />
					</Col>
					<Col>
						<ServiceBadge service={i.service} />
					</Col>
					{/* 						<Col sm="2" className="text-center">
							{getTimeValue(i.start)} - {getTimeValue(i.end)} <br />
							{i.weekdays ?
								<WeekDays weekdays={i.weekdays} />
								: ''}<br />
							<FontAwesomeIcon icon="user" /> &times; {i.operating_units}
						</Col> */}
					<Col sm="1" className="text-center">
						{getFixedNumber(i.size)}
					</Col>
					<Col sm="2">
						{i.risk_area_summary ? <ItemProgress ui_list={ui_list} risk_list={i.risk_area_summary} item={i} ui_list={ui_list} /> : ''}
					</Col>
					<Col sm="2">
						<Row>
							<Col>
								{i.process_check_section ?
									<>
										{i.process_check_section.map((s, k) => (
											<Badge key={k} variant="light">{process_check_section[s].label}</Badge>
										))}
									</>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{i.lqa ?
									<>
										lqa: {i.lqa}
									</>
									: ''}
							</Col>
						</Row>
					</Col>
					<Col sm="2" className="text-center">
						<ButtonGroup>
							{i.process_list && Object.keys(i.process_list).length > 0 ?
								<Button disabled variant="info" size="sm"><FontAwesomeIcon icon="check" /></Button>
								: ''}
							<ModalProcessCheck w={workarea} element={i} ui_list={ui_list} t={t} functions={{ edit: handleUpdateIntervention }} canWrite={canWrite} childRefAlert={childRefAlert} />
						</ButtonGroup>
					</Col>
				</Row>
			</Col>
		)
	} else {
		return <><Col>

			<Spinner animation="grow" role="status" />

		</Col></>
	}

}



////////////////////////////////////////////////////////////////////////////////////////////////////////
export function ModalProcessCheck({ w, element, functions, t, ui_list, canWrite, _preview, res_data, childRefAlert }) {
	const { domain } = useContext(DomainContext)
	const { contractId, structureElements, roomtype_dict, risk_area_list, risk_area_dict, service_dict, operation_dict } = useContext(GlobalDataContext)

	//	const [curWorkarea, setCurWorkarea] = useState(newworkarea)
	const [editedElement, setEditedElement] = useState(false)
	const [currentEditIntervention, setCurrentEditIntervention] = useState(element)
	const [process_list, setProcess_list] = useState(element.process_list || {})
	const [showEditProcessCheck, setShowEditProcessCheck] = useState(false)
	const [preview, showPreview] = useState(_preview)

	const processtiming_dict = processcontroltiming_dict({ t })

	let service_name = ' '
	try {
		service_name = service_dict[element.service.id].name + " |"
	} catch (err) {
		service_name = ''
	}

	let check_section = processCheckSection_dict({ t })
	if (element.cart_element_list && element.cart_element_list.filter(e => e.category_type === 'machine').length > 0) {
		check_section.equipment.list.push({ id: "machine", label: t('global.labels.machines') })
		/*
		const c = checkComponentPresence({ id: 'machine' }, check_section.equipment.list, 'id')
		console.log("index machine", c, check_section)
			if (c!==-1){
			check_section.equipment.list = check_section.equipment.list.slice(c, 1)
		} */

	}
	if (element.cart_element_list && element.cart_element_list.filter(e => e.category_type === 'cart').length > 0) {
		check_section.equipment.list.push({ id: "cart", label: t('global.labels.carts') })
		/* 		const c = checkComponentPresence({ id: 'cart' }, check_section.equipment.list, 'id')
				console.log("index cart", c, check_section)
				if (c!==-1){
					check_section.equipment.list = check_section.equipment.list.slice(c, 1)
				} */
	}


	//-----Process check form
	const handleShowEditProcessCheck = (i) => {
		//setCurWorkarea(w)
		setCurrentEditIntervention({ ...element })
		setShowEditProcessCheck(true)
		setEditedElement(false)
	}

	const handleCloseProcessCheck = () => {
		{
			preview ?
				showPreview(false)
				:
				setShowEditProcessCheck(false);
		}
	}

	const handleSaveProcessCheck = async (index) => {
		console.log('Processcheck edit intervention', currentEditIntervention.index)
		const return_data = await editIntervention2(domain, contractId, currentEditIntervention.id, currentEditIntervention)
		//intervention_dict[currentEditIntervention.id] = currentEditIntervention
		//		const c = checkComponentPresence(currentEditIntervention, editlist, 'id')
		//		if (c !== -1) {

		//		editlist.splice(c, 1, currentEditIntervention)
		//		console.log(editlist)
		//		tmp_editworkarea.intervention_list = editlist
		//}
		functions.edit(currentEditIntervention)
		//		setCurWorkarea({ ...curWorkarea, 'intervention_list': editlist })
		setShowEditProcessCheck(false);
	}


	const onChangeHandlerIntervention = (el) => {
		let { name, value, type } = el
		let sub = undefined
		try {
			sub = el.getAttribute('sub')
		} catch {
			sub = undefined;
		}
		console.log(name, value, type, sub)
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			try {

				let oldcheck = [...currentEditIntervention[name]]
				if (el.checked) {
					if (oldcheck && oldcheck.indexOf(value) < 0) {
						oldcheck.push(value)
					} else {
						oldcheck = [value]
					}
				} else {
					if (oldcheck.indexOf(value) >= 0) {
						oldcheck.splice(oldcheck.indexOf(value), 1)
					}
				}
				value = oldcheck
			} catch (err) {
				value = [value]

			}
		}
		if (sub) {
			value = { ...currentEditIntervention[sub], [name]: value }
			name = sub;
		}
		console.log("intervention new value", name, value)
		setCurrentEditIntervention({ ...currentEditIntervention, [name]: value })
		setEditedElement(true)
	}


	const onChangeProcessList = (el) => {
		console.log("CHANGE PROCESS LIST", el, currentEditIntervention.process_list)
		let editdict = {}
		if (currentEditIntervention.process_list) {
			editdict = { ...currentEditIntervention.process_list }
		}
		editdict[el.name] = el.value
		console.log("new process list complete", editdict)
		setCurrentEditIntervention({ ...currentEditIntervention, process_list: editdict })
		setEditedElement(true)
		//		onChangeHandlerIntervention({ name: 'process_list', value: editdict })
	}


	//	useEffect(() => {
	//		console.log("UPDATE INTERVENTION", currentEditIntervention)
	//	}, [currentEditIntervention])

	const [summary_data, set_summary] = useState({ furniture: [], environment_element: [], economic_material: [], action_list: [] })


	const initValues = () => {

		let temp_furniture_list_summary = []
		let temp_environment_element_list_summary = []
		let temp_economic_material_list_summary = []
		let temp_operation_dict = {}
		let rt_prev_list = []
		//console.log("elementr",currentEditIntervention, element, element.room_list, preview)
		if (element.room_list) {
			element.room_list.map((r) => {
				const rt = roomtype_dict[r.roomtype.id]
				//console.log(rt)
				if (rt && rt_prev_list.indexOf(rt.id) === -1) {
					rt_prev_list.push(rt.id)
					rt.service_list?.filter(s => (s.service.id === element.service.id)).map(s => {
						if (s.operation_list) {
							s.operation_list.map(o => {
								const one_o = operation_dict[o.id]
								if (one_o) {

									if (!temp_operation_dict[one_o.id]) {
										temp_operation_dict[one_o.id] = one_o
									}
								} else {
									childRefAlert.current.addAlert('warning', `Operazione non più presente (tipologia di locale: ${rt.name}, prestazione: ${s.service.name}, operazione: ${o.name} )`)
									console.error(o)
								}
							})
						}
					})
				}
				//console.log(dimensions)
			})
		}
		//console.log(temp_furniture_list_summary, temp_environment_element_list_summary, temp_economic_material_list_summary)
		set_summary({
			furniture: temp_furniture_list_summary,
			environment_element: temp_environment_element_list_summary,
			economic_material: temp_economic_material_list_summary,
			operation_dict: temp_operation_dict
		})
		//		console.log("process list", element.process_list)
	}


	useEffect(() => {
		//console.log("***************************______________________RES DATA")
		initValues()
	}, [currentEditIntervention])

	useEffect(() => {
		//console.log("...INIT PREVIEW")
		initValues()
	}, [])
	//set_summary({furniture:temp_furniture_list_summary, emvoronment_element:temp_environment_element_list_summary, economic_material:temp_economic_material_list_summary})









	return (
		<>
			<Button variant="outline-info" size="sm" onClick={() => handleShowEditProcessCheck(element)} ><FontAwesomeIcon icon="clipboard-check" />{t('workarea.labels.processcheck')}</Button>


			<Modal show={showEditProcessCheck} onHide={handleCloseProcessCheck} fullscreen backdrop="static" className="bg-secondary" scrollable={true}>
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title> {element.name} | {service_name} <span className="text-info"><FontAwesomeIcon icon="clipboard-check" /> &nbsp;{t('workarea.labels.processcheck')} </span>
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
						{preview ?
							<>
								<ButtonGroup size="sm">
									{Object.keys(processtiming_dict).map((tk, k) => (
										<Button key={k} variant={preview === tk ? 'secondary' : 'outline-secondary'} onClick={() => showPreview(tk)}>{processtiming_dict[tk].label}</Button>
									))}

								</ButtonGroup>
							</> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						{preview ?
							<>
								<Row className="border-bottom mb-3">
									<Col sm="3">
										{t('global.labels.lqa')}
									</Col>
									<Col>
										<Badge bg="info" pill >{currentEditIntervention.lqa}</Badge>
									</Col>
								</Row>
								{currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('operator') >= 0 ?
									<>
										<Row className="bg-primary text-white">
											<Col>
												{t('workarea.labels.checkoperator')}
											</Col>
										</Row>
										{check_section['operator'].list.map((s, k) => (
											<ElementFormCheckSection key={k} element={currentEditIntervention} t={t} summary_data={summary_data} functions={{ edit: onChangeProcessList }} global_section='operator' section={s} preview={preview} canWrite={canWrite} res_data={res_data} />
										))}
									</>
									: ''}
								{currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('equipment') >= 0 ?
									<>
										<Row className="bg-primary text-white">
											<Col>
												{t('workarea.labels.checkequipment')}
											</Col>
										</Row>
										{check_section['equipment'].list.map((s, k) => (
											<ElementFormCheckSection key={k} element={currentEditIntervention} t={t} summary_data={summary_data} functions={{ edit: onChangeProcessList }} global_section='equipment' section={s} preview={preview} canWrite={canWrite} res_data={res_data} />
										))}
									</>
									: ''}
								{currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('skill') >= 0 ?
									<>
										<Row className="bg-primary text-white">
											<Col>
												{t('workarea.labels.checkskill')}
											</Col>
										</Row>
										{check_section['skill'].list.map((s, k) => (
											<ElementFormCheckSection key={k} element={currentEditIntervention} t={t} summary_data={summary_data} functions={{ edit: onChangeProcessList }} global_section='skill' section={s} preview={preview} canWrite={canWrite} res_data={res_data} />
										))}
									</>
									: ''}
								{/* 								<Row>
									<Col className="text-primary border-bottom h5">
										{t('checks.labels.global_questions')}
									</Col>
								</Row>
								{ui_list.global_question_list ?
									<>
										{ui_list.global_question_list.map((q, k) => (
											<Row key={k}>
												<Col>
													{q.text}
												</Col>
											</Row>
										))}
									</>
									: ''
								} */}
							</>
							:
							<Tab.Container id="left-tabs-example" defaultActiveKey="tabDetails">
								<Row>
									<Col sm={2} >
										<Nav variant="pills" className="flex-column">
											<Nav.Item variant="secondary" className="mb-1">
												<Nav.Link eventKey="tabDetails">{t('company.labels.details')} </Nav.Link>
											</Nav.Item>
											{currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('operator') >= 0 ?
												<Nav.Item className="mb-1">
													<Nav.Link eventKey="tabCheckOperator">{t('workarea.labels.checkoperator')}</Nav.Link>
												</Nav.Item>
												: ''}
											{currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('equipment') >= 0 ?
												<Nav.Item className="mb-1">
													<Nav.Link eventKey="tabCheckEquipment">{t('workarea.labels.checkequipment')}</Nav.Link>
												</Nav.Item>
												: ''}
											{currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('skill') >= 0 ?
												<Nav.Item className="mb-1">
													<Nav.Link eventKey="tabCheckSkill">{t('workarea.labels.checkskill')}</Nav.Link>
												</Nav.Item>
												: ''}
										</Nav>
									</Col>
									<Col>
										<Tab.Content>
											<Tab.Pane eventKey="tabDetails">
												<Row className="border-bottom mb-1">
													<Col sm="3" >
														{t('workarea.labels.enabled_checks')}
													</Col>
													<Col sm="5">
														<Col>
															<Form.Check type="checkbox" name="process_check_section" disabled={!canWrite} value="operator" checked={currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('operator') >= 0} label={t('workarea.labels.checkoperator')} onChange={(event) => onChangeHandlerIntervention(event.currentTarget)} />
														</Col>
														<Col>
															<Form.Check type="checkbox" name="process_check_section" disabled={!canWrite} value="equipment" checked={currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('equipment') >= 0} label={t('workarea.labels.checkequipment')} onChange={(event) => onChangeHandlerIntervention(event.currentTarget)} />
														</Col>
														<Col>
															<Form.Check type="checkbox" name="process_check_section" disabled={!canWrite} value="skill" checked={currentEditIntervention.process_check_section && currentEditIntervention.process_check_section.indexOf('skill') >= 0} label={t('workarea.labels.checkskill')} onChange={(event) => onChangeHandlerIntervention(event.currentTarget)} />
														</Col>
													</Col>
												</Row>
												<Form.Group as={Row} controlId="roomtypeName" className="border-bottom">
													<Form.Label column sm="2">{t('global.labels.lqa')}</Form.Label>
													<Col sm="8">
														<Form.Control type="number" name="lqa" step="0.01" min="0" value={currentEditIntervention.lqa} onChange={(event) => onChangeHandlerIntervention(event.currentTarget)} className="mb-2" />
													</Col>
												</Form.Group>
											</Tab.Pane>
											<Tab.Pane eventKey="tabCheckOperator">
												{check_section['operator'].list.map((s, k) => (
													<ElementFormCheckSection key={k} element={currentEditIntervention} t={t} summary_data={summary_data} functions={{ edit: onChangeProcessList }} global_section='operator' section={s} canWrite={canWrite} />
												))}
											</Tab.Pane>
											<Tab.Pane eventKey="tabCheckEquipment">
												{check_section['equipment'].list.map((s, k) => (
													<ElementFormCheckSection key={k} element={currentEditIntervention} t={t} summary_data={summary_data} functions={{ edit: onChangeProcessList }} global_section='equipment' section={s} canWrite={canWrite} />
												))}
											</Tab.Pane>
											<Tab.Pane eventKey="tabCheckSkill">
												{check_section['skill'].list.map((s, k) => (
													<ElementFormCheckSection key={k} element={currentEditIntervention} t={t} summary_data={summary_data} functions={{ edit: onChangeProcessList }} global_section='skill' section={s} canWrite={canWrite} />
												))}
											</Tab.Pane>
										</Tab.Content>
									</Col>
								</Row>
							</Tab.Container>
						}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseProcessCheck}>
						{t('modal.close')}
					</Button>
					{!preview ?
						<>

							<Button onClick={() => showPreview('pre')} ><FontAwesomeIcon icon="eye" />{t('structure.labels.show_preview')}</Button>
							{canWrite ?
								<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSaveProcessCheck}>
									<FontAwesomeIcon icon="save"></FontAwesomeIcon> {t('modal.save')}
								</Button>
								: ''}
						</>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
const GoodSection = ({ t, section_list, section }) => {
	if (section_list && section_list.length) {
		let intended_use_dict = materialIntendedUse_dict({ t })
		return (
			<>
				<Row>
					{section_list.map((e, kf) => (
						<Col key={kf} className="mb-1" sm="3">
							<Row className="p-2">
								<Col className="rounded border border-info">
									<Row>

										<Col className="p-1">
											<Row>
												<Col>
													{e.name}
												</Col>
											</Row>
											<Row>
												{section === 'cleaningmaterial' ?
													<Col>
														<Badge className="ms-1 me-1" pill variant="info">qt. {e.quantity}</Badge>
													</Col>
													: ''}
												<Col>
													{getBaseObjectValue(e.category, 'name', 'it')}
												</Col>
											</Row>
											<Row>
												<Col className="font-italic">
													{getBaseObjectValue(intended_use_dict, e.intended_use, 'label')}
												</Col>
											</Row>
										</Col>
										<Col className="text-center" sm="3">
											{getElementPreview(e, '40px')}
										</Col>
									</Row>
								</Col>
							</Row>
						</Col>
					))}

				</Row>
			</>
		)


	} else {
		return (<></>)
	}
}


////////////////////////////////////////////////////////////////////////////////////////////////////////
function ElementFormCheckSection({ element, section, functions, t, global_section, summary_data, preview, canWrite, res_data }) {
	const newprocess = {
		id: makeid(8),
		description: '',
		coefficient: 0,
		control_mode: '',
		timing_type: [],
		section: section.id,
		process_type: 'question',
		action_list: []
	}


	const [showProcessEdit, setShowProcessEdit] = useState(false)
	const [is_new, setNewProcessEdit] = useState(true)
	const [curProcess, setCurProcess] = useState(newprocess)
	const [editedElement, setEditedElement] = useState(false)
	const [showOperationEdit, setShowOperationEdit] = useState(false)
	const { messagegroup_list } = useContext(GlobalDataContext)

	const processtiming_dict = processcontroltiming_dict({ t })
	const timing_mode_dict = timingMode_dict({ t })
	//----process
	const handleCloseProcessParam = () => setShowProcessEdit(false);
	const handleSaveProcessParam = () => {
		console.log('----save', is_new, curProcess)
		setShowProcessEdit(false);
		if (is_new) {
			let newprocess_list = []
			if (element.process_list) {
				newprocess_list = element.process_list[section.id] || []
			}
			newprocess_list.push(curProcess)
			functions.edit({ name: section.id, value: newprocess_list })
			setEditedElement(false)
		} else {
			let editlist = element.process_list[section.id] || []
			const c = checkComponentPresence(curProcess, element.process_list[section.id], 'id')
			console.log("INDEX", c, element.process_list[section.id], curProcess.id)
			if (c >= 0) {
				editlist.splice(c, 1, curProcess)

			}
			functions.edit({ name: section.id, value: editlist })
			setEditedElement(false)
		}
	}

	const handleImportProcess = (source_list) => {
		console.log("pre import", element.process_list, source_list)
		let newprocess_section_list = []
		if (element.process_list) {
			newprocess_section_list = element.process_list[section.id] || []
		}
		newprocess_section_list = [...newprocess_section_list, ...source_list]
		functions.edit({ name: section.id, value: newprocess_section_list })

		console.log("AFTER import", global_section, section.id, newprocess_section_list)

	}
	const handleDeleteProcess = (el, index) => {
		let editlist = [...element.process_list[section.id]]
		const c = checkComponentPresence(el, editlist, 'id')
		console.log("DELETE PROCESS", el, c, editlist)
		if (c >= 0) {
			editlist.splice(c, 1)
			functions.edit({ name: section.id, value: editlist })
		}
	}

	const handleShowNewProcess = () => {
		setCurProcess(newprocess)
		setNewProcessEdit(true)
		setShowProcessEdit(true)
		setEditedElement(false)
	}

	const handleShowEditProcess = (i, index) => {
		console.log('edit', i, index)
		i.index = index
		setCurProcess(i)
		setNewProcessEdit(false)
		setShowProcessEdit(true)
		setEditedElement(false)
	}

	const onChangeHandlerProcess = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = []
			try {
				oldcheck = [...curProcess[name]]
			} catch (err) { }
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setCurProcess({ ...curProcess, [name]: value })
		setEditedElement(true)
	}

	const handleShowEditOperation = () => {
		setShowOperationEdit(true)
	}

	const handleCloseEditOperation = () => {
		setShowOperationEdit(false)
	}


	const checkPresence = () => {
		try {
			console.log(element.process_list[section.id], section.id)
		} catch (err) {

			console.log("---error check", section.id, err)
		}
	}


	const ExternalSummary = () => {
		//console.log("global section", global_section, section.id)
		if (global_section === 'equipment') {
			//console.log("cart element list", element.cart_element_list)
			if (element.cart_element_list && element.cart_element_list.length > 0) {

				if (preview && element.cart_element_list.filter(e => e.category_type === section.id).length === 0) {
					return (
						<>
						</>)

				} else {
					return (
						<>
							<Row>
								<Col className="text-info">
									{section.label} presente/i nel carrello
								</Col>
							</Row>
							<GoodSection section_list={element.cart_element_list.filter(e => e.category_type === section.id)} section={section.id} t={t} />

						</>

					)
				}
			} else {
				return (
					<>
						<i className="text-muted">{t('workarea.messages.no_cart')}</i>
					</>

				)

			}
		} else if (global_section === 'skill' && section.id === 'procedures') {
			return (
				<>
					<Row>
						<Col className="text-info">
							azioni operative per <Badge>{element.name}</Badge>
						</Col>
						{canWrite && !preview ?
							<Col className="text-end" sm="2">
								{showOperationEdit ?
									<Button variant="outline-secondary" size="sm" onClick={() => handleCloseEditOperation()}><FontAwesomeIcon icon="check"></FontAwesomeIcon>{t('structure.labels.component_modend')}</Button>
									:
									<Button variant="outline-primary" size="sm" onClick={() => handleShowEditOperation()}><FontAwesomeIcon icon="plus-square"></FontAwesomeIcon>{t('workarea.labels.operationcheck_add')}</Button>
								}
							</Col>
							: ''}
					</Row>
					<Row>
						{checkPresence()}
						{element.process_list && element.process_list[section.id] && element.process_list[section.id].filter(f => f.process_type === 'operation').length > 0 ?
							<Col>
								<Row className="bg-light section_title">
									<Col sm="3">
										{t('operations.labels.operation')}
									</Col>
									<Col>
										{t('operations.labels.actions')}
									</Col>
									{canWrite ?
										<Col className="text-center" sm="1">
											{t('global.labels.actions')}
										</Col>
										: ''}
								</Row>

								<OperationSection />

							</Col>
							: ''
						}
						{showOperationEdit ?
							<Col className="editSection">
								<Row className="mb-1" >
									<Col className="bg-secondary text-light ms-1 p-0 text-center" sm="1">{t('global.labels.add')}</Col>
									<Col className="bg-secondary text-light ms-1">{t('structure.labels.component_name')} </Col>
								</Row>
								<OperationEditSection />

							</Col>

							: ''}

						{/* 							<FurnitureLikeSection section_list={summary_data.furniture} />
							<FurnitureLikeSection section_list={summary_data.economic_material} />
							<FurnitureLikeSection section_list={summary_data.environment_element} /> */}
					</Row>
				</>
			)
		} else {
			return (
				<>
				</>
			)
		}
	}
	//------------------------------------------------------------------------------------------------------
	const handleAddOperation = (o) => {
		console.log(o)
		let proc = { ...curProcess }
		proc.operation_id = o
		proc.process_type = 'operation'
		let newprocess_list = []
		if (element.process_list) {
			newprocess_list = element.process_list[section.id] || []
		}
		newprocess_list.push(proc)
		functions.edit({ name: section.id, value: newprocess_list })
		setEditedElement(false)

	}


	//------------------------------------------------------------------------------------------------------
	const handleRemoveOperation = (o) => {
		let editlist = [...element.process_list[section.id]]
		console.log("REMOVE", o, element)
		const c = checkComponentPresence(o, editlist, 'operation_id')
		console.log(c)
		if (c >= 0) {

			editlist.splice(c, 1)
			functions.edit({ name: section.id, value: editlist })
		}

	}




	//------------------------------------------------------------------------------------------------------
	const OperationEditSection = () => {
		console.log("OPERATION DICT", summary_data.operation_dict)
		return (
			<>
				{Object.keys(summary_data.operation_dict).map((o, k) => (
					<Row key={o} className="border-bottom">
						<Col sm="1">
							{!preview ?
								<>
									{element.process_list && element.process_list[section.id] && (checkComponentPresence({ operation_id: o }, element.process_list[section.id].filter(f => f.process_type === 'operation'), 'operation_id') !== -1) ?
										<Button size="sm" disabled variant="outline-info" onClick={() => handleAddOperation(o)}><FontAwesomeIcon icon="check" /> </Button>
										:
										<Button size="sm" variant="light" onClick={() => handleAddOperation(o)}><FontAwesomeIcon icon="plus-square" />{summary_data.operation_dict[o].do_process_check ? <FontAwesomeIcon icon="star" /> : ''} </Button>
									}
								</>
								: ''}
						</Col>
						<Col>
							{summary_data.operation_dict[o].do_process_check ?
								<OverlayTrigger
									placement="right"
									delay={{ show: 250, hide: 200 }}
									overlay={renderCheckTooltip}
								>
									<FontAwesomeIcon className="text-info" icon="star" />
								</OverlayTrigger>
								: ''}
							{summary_data.operation_dict[o].name}
						</Col>
					</Row>
				))}
			</>
		)

	}



	//------------------------------------------------------------------------------------------------------
	const OperationSection = () => {
		console.log(element.process_list[section.id].filter(f => f.process_type === 'operation'))
		return (
			<>
				{element.process_list[section.id].filter(f => f.process_type === 'operation').map((o, k) => (
					<Row key={k} className="border-bottom">
						{console.log("----test", summary_data.operation_dict, o.operation_id)}
						<Col sm="3">
							{summary_data.operation_dict[o.operation_id].do_process_check ?
								<OverlayTrigger
									placement="right"
									delay={{ show: 250, hide: 200 }}
									overlay={renderCheckTooltip}
								>
									<FontAwesomeIcon className="text-info" icon="star" />
								</OverlayTrigger>
								: ''}
							{summary_data.operation_dict[o.operation_id].name}
						</Col>
						<Col>
							<OperationActionList a={summary_data.operation_dict[o.operation_id]} />
						</Col>
						{!preview ?
							<Col className="text-center" sm="1">
								<Button size="sm" variant="outline-danger" onClick={() => handleRemoveOperation(o)}><FontAwesomeIcon icon="trash" /> </Button>
							</Col>
							: ''}
					</Row>
				))}
			</>
		)

	}


	//------------------------------------------------------------------------------------------------------
	const OperationActionList = ({ a }) => {

		if (a.action_list && a.action_list.length > 0) {
			return (
				<>
					{a.action_list.map((o, k) => (
						<Row key={k}>
							- {o.description}

						</Row>

					))}
				</>
			)
		} else {
			return (<i>nessuna azione configurata</i>)
		}
	}



	const testData = () => {
		if (element.process_list && element.process_list[section.id]) {
			console.log("HAS DATA", section.id, element.process_list[section.id])

			return (element.process_list[section.id].filter((i) => (!i.process_type || i.process_type === 'question')))
		}
		console.log("NO HAS DATA", section.id)
		return (element.process_list[section.id])


	}




	// =====================================================================================================================================
	return (
		<>
			<Card className="mb-3">
				<Card.Header>
					<Container fluid>
						<Row>
							<Col className="text-primary h5">
								{section.label}
							</Col>
							{!preview && canWrite ?
								<Col className="text-end">
									<ButtonGroup>
										<ModalImportProcess section={section} dest_intervention_id={element.id} dest_param_list={element.process_list ? element.process_list[section.id] : []} functions={{ import: handleImportProcess }} t={t} />
										<Button variant="outline-primary" size="sm" onClick={() => handleShowNewProcess()}><FontAwesomeIcon icon="plus-square" />{t('workarea.labels.process_add')}</Button>
									</ButtonGroup>
								</Col>
								: ''}
						</Row>

					</Container>

				</Card.Header>
				<Card.Body className="p-2">
					<Container fluid>
						<Row>
							<Col>
								<ExternalSummary />
							</Col>
						</Row>
						<Row >
							{element.process_list && element.process_list[section.id] && element.process_list[section.id].filter((i) => (!i.process_type || i.process_type === 'question')
								&& (!preview || !i.timing_type || i.timing_type.indexOf(preview) >= 0)).length > 0 ?
								<Col>
									<Row >
										<Col className="bg-secondary text-light ms-1">{t('global.labels.description')} </Col>
										<Col className="bg-secondary text-light ms-1 p-1" sm="1">{t('global.labels.timing_type')} </Col>
										<Col className="bg-secondary text-light ms-1 p-1 text-center" sm="1">{t('workarea.labels.weight_coefficient_short')} </Col>
										<Col className="bg-secondary text-light ms-1" sm="1">{t('workarea.labels.control_tool')} </Col>
										<Col className="bg-secondary text-light ms-1">{t('workarea.labels.noncompliance_action')} </Col>
										{!preview ?
											<Col className="bg-secondary text-light ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
											: ''}
									</Row>
									{element.process_list[section.id].filter((i) => (!i.process_type || i.process_type === 'question') && (!preview || !i.timing_type || i.timing_type.indexOf(preview) >= 0)).map((a, key) => (
										<Row key={key} className="border-bottom mb-1 pt-1">
											{console.log("LIST", element.process_list[section.id].filter((i) => (!i.process_type || i.process_type === 'question') && (!preview || !i.timing_type || i.timing_type.indexOf(preview) >= 0)))}
											<Col className="font-weight-bold">{a.description}</Col>
											<Col sm="1" className="p-1">
												{a.timing_type ?
													<>
														{a.timing_type.map((tp, tkey) => (
															<Badge key={tkey} variant="light" className="me-1">
																{processtiming_dict[tp].label}
															</Badge>
														))}
													</> : ''}
											</Col>
											<Col sm="1">{a.coefficient}</Col>
											<Col sm="1">{a.control_mode}</Col>
											{res_data ?
												<Col>
													<ResultBadge res={res_data.result_dict["question_" + a.id]} ratio={a.coefficient} />
												</Col>
												:
												<Col>
													{a.action_list.length > 0 ?
														<>
															<Row className="bg-light section_title mb-1">
																<Col >{t('workarea.labels.action')} </Col>
																<Col >{t('workarea.labels.responsability')} </Col>
																<Col >{t('ticket.labels.receiver')}</Col>
																<Col >{t('workarea.labels.timing')} </Col>
															</Row>
															{a.action_list.map((a, key) => (
																<Row key={key} className="border-bottom mb-1 pt-1">
																	<Col className="font-weight-bold p-1 ">{a.action}</Col>
																	<Col>{a.responsability}</Col>
																	<Col>{getValue(getSelectedElement(a.receiver_group, messagegroup_list, 'id'), 'name')}</Col>
																	<Col>{a.timing_mode && a.timing_mode.length > 0 ?
																		<>
																			{a.timing_mode.map((m, k) => (
																				<Badge key={k}>{getValue(timing_mode_dict[m], 'label')}</Badge>
																			))}
																		</>
																		: ''
																	}
																		{a.timing.length > 0 ?
																			<>
																				({a.timing})
																			</> : ''}
																	</Col>
																</Row>))
															}
														</>
														: <i>{t('workearea.messages.no_noncompliance_actions')}</i>
													}
												</Col>
											}
											{!preview && canWrite ?
												<Col className="text-center" sm="1">
													<ButtonGroup>
														<Button size="sm" variant="info"><FontAwesomeIcon icon="pencil-alt" onClick={() => handleShowEditProcess(a, key)} /> </Button>
														<Button size="sm" variant="danger"><FontAwesomeIcon icon="trash" onClick={() => handleDeleteProcess(a, key)} /> </Button>
													</ButtonGroup>
												</Col>
												: ''}
										</Row>))
									}
								</Col>
								: <Col className="text-muted"><i>{preview ? '' : <> {t('workarea.messages.no_process')}</>}</i></Col>
							}
						</Row>

					</Container>

				</Card.Body>
			</Card>





			<Modal show={showProcessEdit} onHide={handleCloseProcessParam} size="xl" className="bg-secondary" backdrop="static">
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						{is_new ?
							<>
								<span className="text-primary"><FontAwesomeIcon icon="plus-square" />  {t('workarea.labels.new_process')}</span> | {section.label}
							</>
							:
							<>
								<span className="text-info"><FontAwesomeIcon icon="pencil-alt" /> {t('workarea.labels.edit_process')}</span> | {section.label}
							</>
						}
						&nbsp;
						{editedElement ? <FontAwesomeIcon icon="save" /> : ''}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
							<Form.Label column sm="2">{t('global.labels.description')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" name="description" value={curProcess.description} onChange={(event) => onChangeHandlerProcess(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
							<Form.Label column sm="2">{t('workarea.labels.weight_coefficient')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" name="coefficient" value={curProcess.coefficient} onChange={(event) => onChangeHandlerProcess(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
							<Form.Label column sm="2">{t('workarea.labels.control_tool')}</Form.Label>
							<Col sm="8">
								<Form.Control size="sm" type="text" name="control_mode" value={curProcess.control_mode} onChange={(event) => onChangeHandlerProcess(event.currentTarget)} />
							</Col>
						</Form.Group>
						<Form.Group as={Row} controlId="supplierFirstName" className="border-bottom">
							<Form.Label column sm="2">{t('workarea.labels.timing_control_type')}</Form.Label>
							<Col sm="8">
								<Form.Check type="checkbox" name="timing_type" checked={curProcess.timing_type && curProcess.timing_type.indexOf("pre") >= 0} value="pre" label={t('workarea.labels.timing_type_pre')} onChange={(event) => onChangeHandlerProcess(event.currentTarget)} />
								<Form.Check type="checkbox" name="timing_type" checked={curProcess.timing_type && curProcess.timing_type.indexOf("during") >= 0} value="during" label={t('workarea.labels.timing_type_during')} onChange={(event) => onChangeHandlerProcess(event.currentTarget)} />
								<Form.Check type="checkbox" name="timing_type" checked={curProcess.timing_type && curProcess.timing_type.indexOf("post") >= 0} value="post" label={t('workarea.labels.timing_type_post')} onChange={(event) => onChangeHandlerProcess(event.currentTarget)} />
							</Col>
						</Form.Group>
						<ElementFormNoncomplianceAction functions={{ edit: onChangeHandlerProcess }} element={curProcess} t={t} />
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseProcessParam}>
						{t('modal.close')}
					</Button>
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} onClick={handleSaveProcessParam}>
							<FontAwesomeIcon icon="save"></FontAwesomeIcon> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>

		</>
	)
}

////////////////////////////////////////////////////////////////////////////////////////////////////////
function checkParamPresence(source_list, dest_list) {
	if (dest_list) {
		let ispresent = false
		source_list.forEach(sp => {
			dest_list.forEach(dp => {
				if (sp.id === dp.id) {
					console.log("------presence")
					ispresent = true
				}
			})
		});
		return ispresent
	} else {
		console.log("----no data ")
		return false
	}

}



////////////////////////////////////////////////////////////////////////////////////////////////////////
const ModalImportProcess = ({ dest_intervention_id, dest_param_list, section, functions, t }) => {

	const [showImportProcessCheck, setShowImportProcessCheck] = useState(false)
	const childRefAlert = useRef()

	//-----Process check form
	const handleShowImportProcessCheck = (w, i) => {
		setShowImportProcessCheck(true)
	}

	const handleCloseProcessCheck = () => {
		setShowImportProcessCheck(false);
	}
	const handleImportSectionParameters = (d) => {
		if (!checkParamPresence(d, dest_param_list)) {
			functions.import(d)
			setShowImportProcessCheck(false);
		} else {
			childRefAlert.current.addAlert('danger', "uno o più parametri già presenti nell'intervento di destinazione. Cancellarli per poter proseguire")
		}
	}
	let section_list = []

	for (let d in intervention_dict) {
		const inter = intervention_dict[d]
		if (d !== dest_intervention_id && inter.process_list && inter.process_list[section.id] && inter.process_list[section.id].filter((i) => i.section === section.id && (!i.process_type || i.process_type === 'question')).length > 0) {
			section_list.push({
				intervention_id: inter.id,
				name: inter.name,
				section_process_list: inter.process_list[section.id].filter((i) => i.section === section.id && (!i.process_type || i.process_type === 'question'))
			})
		}
	}

	//	console.log("import summary", section, section_list)

	return (

		<>
			<Button variant="outline-info" size="sm" disabled={section_list.length === 0} onClick={() => handleShowImportProcessCheck()}>
				<FontAwesomeIcon icon="arrow-right" />{t('workarea.labels.checkprocess_import')}
			</Button>
			{showImportProcessCheck ?
				<Modal show={showImportProcessCheck} onHide={handleCloseProcessCheck} size="xl" className="bg-secondary" >
					<AlertSection ref={childRefAlert} />
					<Modal.Header closeButton>
						<Modal.Title>
							{t('workarea.labels.checkprocess_import')} | {section.label}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container fluid>
							{section_list.length > 0 ?
								<>
									<Row className="mb-1">
										<Col className="bg-secondary text-light ms-1">{t('workareas.labels.intervention_name')} </Col>
										<Col className="bg-secondary text-light ms-1">{t('workareas.labels.process_indicator')} </Col>
										<Col className="bg-secondary text-light ms-1 text-center" sm="1">{t('global.labels.actions')}</Col>
									</Row>
									{section_list.map((s, k) => (
										<Row key={k} className="element_row border-bottom mb-1 pb-1">
											<Col>
												{s.name}
											</Col>
											<Col>
												{s.section_process_list.map((p, kp) => (
													<Row key={kp}>
														<Col>
															{p.description}
														</Col>
													</Row>
												))}
												<Row>

												</Row>
											</Col>
											<Col sm="1">
												<Button variant="info" onClick={() => handleImportSectionParameters(s.section_process_list)} size="sm" >
													<FontAwesomeIcon icon="arrow-right" />{t('global.labels.import')}</Button>
											</Col>
										</Row>
									))}
								</>
								: <i>{t('workareas.message.no_indicators')}</i>}
						</Container>

					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseProcessCheck}>
							{t('modal.close')}
						</Button>
					</Modal.Footer>
				</Modal>
				: ''}
		</>

	)

}



////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * return filter section with modal open button and button for filterered parameters
 * @param {t, ui_list, filterAppliedList, setFilterAppliedList} param0 
 */
export const ModalFilter = ({ t, ui_list, filterAppliedList, setFilterAppliedList, defaultfilter, intervention_type }) => {
	//	const { intervention_dict } = useContext(InterventionContext)
	const { service_dict } = useContext(GlobalDataContext)

	const [showFilter, setShowFilter] = useState(false)


	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const resetAppliedFilter = (section) => {
		if (section) {
			setFilterAppliedList({ ...filterAppliedList, [section]: [] })
		} else {
			setFilterAppliedList(defaultfilter)
		}
		setShowFilter(false)
	}

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}


	function checkInterventionPresence(d) {
		for (let i in intervention_dict) {
			if (intervention_dict[i].service && intervention_dict[i].service.id === d) {
				return true
			}
		}
		return false
	}

	return (
		<>

			<ButtonGroup>
				<Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
				{filterAppliedList.service.length > 0 ?
					<>
						{Object.keys(service_dict).map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.service.indexOf(d) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.service.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('service', d)}>
								{service_dict[d].name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.service.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('service')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
			</ButtonGroup>




			<Modal show={showFilter} onHide={handleCloseFilter} centered size="lg">
				<Modal.Header>
					<Modal.Title>

						<FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Row>
							<Col>
								{t('global.labels.filter_services')}
							</Col>
						</Row>
						<Row>
							{service_dict ?
								<>
									{Object.keys(service_dict).filter(d => service_dict[d].type === intervention_type).map((d, k) => (
										<Col sm="4" key={k}>
											<Button block size="sm" className="ms-1 mb-1 text-start" disabled={!checkInterventionPresence(d)} variant={filterAppliedList.service.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
												onClick={(e) => updateAppliedFilter('service', d)}>
												<ServiceBadge service={service_dict[d]} />
											</Button>
										</Col>
									))}
								</>
								: ''
							}
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseFilter}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}







export default translate()(ProcessCheck)