import {firestore} from '../firebaseIndex'


/**
 * 
 * 
 * @param {id} domainId id dominio
 * @param {id} contractId id contratto
 * @param {id} beaconId beacon id we are looking for related department
 */
export const getBeacon = async (domainId, contractId, beaconId) => {
    try {
        const beaconDoc = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('beacons')
            .doc(beaconId).get()


        if (beaconDoc && beaconDoc.data()) {
            const firebaseData = beaconDoc.data()
            const beacontData = {
                ...firebaseData,
                id: beaconDoc.id,
            }
            return beacontData;
        }

        console.log(`Unable to find beacon [${beaconId}]`)
        return undefined; // no beacon 
    } catch (e) {
        console.error("getBeacon error: ", e)
        return { error: e }
    }
}




/**
 * 
 * 
 * @param {id} domainId id dominio
 * @param {id} contractId id contratto
 * @param {id} departmentId department id we are looking for beacon
 */
export const getBeaconFromDepartment = async (domainId, contractId, departmentId) => {
    try {
        const beaconList = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('beacons')
            .where("departmentId", "==", departmentId)
            .get()
            .then((querySnapshot) => {
                let list = []
                if (querySnapshot) {

                    querySnapshot.forEach((beaconDoc) => {
                        console.log(beaconDoc.id, " => ", beaconDoc.data());
                        const firebaseData = beaconDoc.data()
                        const beaconData = {
                            ...firebaseData,
                            id: beaconDoc.id,
                        }
                        list.push(beaconData)
                    });
                }
                    return list
            })
            .catch((error) => {
                console.log("getBeaconFromDepartment: Error getting documents: ", error);
                return { error }
            });

        return beaconList;
    } catch (e) {
        console.error("getBeaconFromDepartment error: ", e)
        return { error: e }
    }
}




/**
 * 
 * 
 * @param {id} domainId id dominio
 * @param {id} contractId id contratto
 * @param {id} beaconId id beacon
 * @param {data} beaconData data
 */
export const addBeacon = async (domainId, contractId, beaconId, beaconData) => {
    console.log(domainId, contractId, beaconId, beaconData)

        const res = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('beacons')
            .doc(beaconId)
            .set(beaconData)
            .then((docRef) => {
                console.log("Document written with ID: ", docRef);
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
                return { error: error }
            });
            console.log("addBeacon", res)

            return ({...beaconData })

        let e = ''
        console.error("addBeacon error: ", e)
        return { error: e }
}



/**
 * 
 * 
 * @param {id} domainId id dominio
 * @param {id} contractId id contratto
 * @param {id} beaconId id beacon
 * @param {data} newBeaconData data
 */
export const updateBeacon = async (domainId, contractId, beaconId, newBeaconData) => {
    try {
        const beaconRef = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('beacons')
            .doc(beaconId)

        beaconRef.update(newBeaconData)
            .then(() => {
                console.log("Document successfully updated!");
                return newBeaconData
            })
            .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating document: ", error);
                return { error: error }
            });

    } catch (e) {
        console.error("updateBeacon error: ", e)
        return { error: e }
    }
}



/**
 * 
 * 
 * @param {id} domainId id dominio
 * @param {id} contractId id contratto
 * @param {id} beaconId id beacon
 */
export const deleteBeacon = async (domainId, contractId, beaconId) => {
    try {
        const beaconRef = await firestore.collection('DOMAINS')
            .doc(domainId).collection('contracts')
            .doc(contractId)
            .collection('beacons')
            .doc(beaconId)

        beaconRef.delete()
            .then(() => {
                console.log("Document successfully deleted!");
                return true
            }).catch((error) => {
                console.error("Error removing document: ", error);
                return { error: error }
            });

    } catch (e) {
        console.error("deleteBeacon error: ", e)
        return { error: e }
    }
}