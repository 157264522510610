import React, { useContext, useState, createRef, useEffect, useLayoutEffect } from 'react';
import { Container, Row, Col, Button, Modal, Spinner, Badge, OverlayTrigger, Popover, ListGroup, Tooltip, ButtonGroup, Card, Form, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addMinutes, format, getDay, isSameDay } from 'date-fns'
import { useParams } from "react-router-dom"
import 'moment/locale/it';
import 'moment/locale/de';
import { it } from 'date-fns/locale'
import { firebase } from '../../Firebase2/firebaseIndex'

import { filterWeekDay, getValue, myTimestampToDate, compareName, getFloatNumber, getBaseObjectValue, getSelectedElementB, getTimeValue, compareDateParam, compareStartDateParam, rgba2hex, getSelectedElement } from "../../util/ui_utils.js"

import { EmployerIcon, ServiceBadge, ItemProgress, StructureGroupIcon, TicketStatus, IsDeleted, IsModified, SecondarySection, MyBadge } from '../StructureGraphicElements.js';


import { EventRoomList } from '../RoomSelector'

import DomainContext from '../contexts/domainContext.js';
import GlobalDataContext from '../contexts/globaldataContext.js';
import DashboardContext from '../contexts/dashboardContext.js';

import {
	getPavillonList as getPavillonList2,
	getFloorList as getFloorList2,
	getDepartmentById as getDepartmentById2,
} from "../../Firebase2/Storage2/dbBaseCompany"
//import { getAllRoomList } from "../../Firebase2/Storage2/dbRoom"

import { interventionType_dict, popoverTiming, execution_color } from '../constants/global_definitions';
import { firebaseAuth } from '../../provider/AuthProvider';


/////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function EventListDetail({ event_list, t, today_room_dict, event_status, setEventStatus, userData, claudit_trace }) {
	const { domain } = useContext(DomainContext)
	const { contract, contractId, roomtype_dict } = useContext(GlobalDataContext)

	const { intervention_dict, structure_list } = useContext(DashboardContext)
	const [showDetails, setShowDetails] = useState(false)
	const [loading, setLoading] = useState(false)
	const [filterData, setFilterData] = useState({ structure: [], floor: [], department: [] })
	const [filterAppliedList, setFilterAppliedList] = useState({ structure: [], floor: [], department: [] })


	const handleCloseDetails = () => {
		setShowDetails(false)
	}
	const handleOpenDetails = () => {
		setShowDetails(true)
	}



	const updateEvent = (ev, new_status) => {
		let _ev_status = { ...event_status }
		console.log(userData)
		_ev_status[ev.event_id] = { ...ev, validate_status: new_status, controller_id: userData.id, validate_time: firebase.firestore.Timestamp.now() }
		setEventStatus(_ev_status)
	}


	const resetFilter = () => {
		setFilterData({ structure: [], pavillon: [], floor: [], department: [], })
		setFilterAppliedList({ structure: [], pavillon: [], floor: [], department: [], })

	}


	const onChangeStructure = async (el) => {
		//	setLoading(true)
		console.log(el, el.value)
		if (el.value === -1 || el.value === '-1') {
			setFilterData({ ...filterData, pavillon: [], floor: [], department: [], })
			setFilterAppliedList({ structure: [], pavillon: [], floor: [], department: [], })
		} else {
			//			const structure = getSelectedElement(el.value, ui_list.structure_list, 'id')
			let return_data = await getPavillonList2(domain, contractId, el.value)
			setLoading(true)
			if (return_data && return_data.error) {
				//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				return_data = return_data.sort(compareName)
				setFilterData({ ...filterData, pavillon: return_data, floor: [], department: [], })
				setFilterAppliedList({ structure: [el.value], pavillon: [], floor: [], department: [], })
			}
		}
		setLoading(false)

	}


	const onChangePavillon = async (el) => {
		setLoading(true)
		const pavillon = getSelectedElement(el.value, filterData.pavillon, 'id')
		console.log(el.value, pavillon)
		if (pavillon.id) {
			let return_data = await getFloorList2(domain, contractId, filterAppliedList.structure[0], pavillon.id)
			setLoading(true)
			if (return_data && return_data.error) {
				//				childRefAlert.current.addAlert('danger', t('global.amessages.error') + return_data.error)
			} else {
				return_data = return_data.sort(compareName)
				setFilterData({ ...filterData, floor: return_data, department: [], })
				setFilterAppliedList({ ...filterAppliedList, pavillon: [pavillon.id], floor: [], department: [], })
			}
		}
		setLoading(false)
	}


	const onChangeFloor = async (el) => {
		//	setLoading(true)
		//setRoomList([])
		setLoading(true)
		const floor = getSelectedElement(el.value, filterData.floor, 'id')
		console.log(el.value, floor)
		if (floor.id) {
			setFilterAppliedList({ ...filterAppliedList, floor: [floor.id], department: [], })
			//}
		}
		setLoading(false)
	}

	/*        const onChangeDepartment = async (el) => {
			   //	setLoading(true)
			   //setRoomList([])
			   const department = getSelectedElement(el.value, filterData.department, 'id')
			   console.log(el.value, department)
			   if (department.id) {
				   setFilterAppliedList({ ...filterAppliedList, department: [department.id], })
			   } else {
				   setFilterAppliedList({ ...filterAppliedList, department: [], })
			   }
		   }
	*/




	function checkFilter(e) {
		let show = false
		if (filterAppliedList.structure.length === 0) {
			show = true
		} else {
			if (e.extendedProps.intervention_data.structure_list && e.extendedProps.intervention_data.structure_list.some(d => (filterAppliedList.structure.indexOf(d)) >= 0)) {
				if (filterAppliedList.pavillon.length === 0) {
					show = true
				} else {
					if (e.extendedProps.intervention_data.pavillon_list && e.extendedProps.intervention_data.pavillon_list.some(d => (filterAppliedList.pavillon.indexOf(d)) >= 0)) {
						if (filterAppliedList.floor.length === 0) {
							show = true
						} else {
							if (e.extendedProps.intervention_data.floor_list && e.extendedProps.intervention_data.floor_list.some(d => (filterAppliedList.floor.indexOf(d)) >= 0)) {
								show = true
							}

						}
					}
				}
			}
		}
		//	console.log(show)
		return show
	}


	return (

		<>
			<Button onClick={handleOpenDetails} size="sm" ><FontAwesomeIcon icon="info-circle" /> {t('global.labels.details')}</Button>

			<Modal show={showDetails} onHide={handleCloseDetails} fullscreen bsclass="full-modal" backdrop="static" scrollable={true}>
				<Modal.Header closeButton>
					<Modal.Title className="info"><FontAwesomeIcon icon="info-circle" /> Dettagli elenco servizi</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Row>
							<Col sm="1">
								{event_list.filter(d => checkFilter(d)).length} / {event_list.length}
								<FontAwesomeIcon icon="filter" />
							</Col>
							<Col sm="3">
								<Form.Control as="select" className="mb-2" name="structure" onChange={(event) => onChangeStructure(event.currentTarget)} >
									<option value="-1" >{t('structure.labels.select_structure')}</option>
									{structure_list && structure_list.length > 0
										? [structure_list.map((f, k) => (
											<option key={k} value={f.id} >{f.name}</option>
										))]
										: ''
									}
								</Form.Control>
							</Col>
							<Col sm="3">
								{filterData.pavillon && filterData.pavillon.length > 0 ?
									<Form.Control as="select" className="mb-2" name="pavillon" onChange={(event) => onChangePavillon(event.currentTarget)} >
										<option value="-1" >{t('structure.labels.select_pavillon')}</option>
										{filterData.pavillon.map((f, k) => (
											<option key={k} value={f.id} >{f.name}</option>
										))}
									</Form.Control>
									: ''}
							</Col>
							<Col sm="3">
								{filterData.floor && filterData.floor.length > 0 ?
									<Form.Control as="select" className="mb-2" name="floor" onChange={(event) => onChangeFloor(event.currentTarget)} >
										<option value="-1" >{t('structure.labels.select_floor')}</option>
										{filterData.floor.map((f, k) => (
											<option key={k} value={f.id} >{f.name}</option>
										))}
									</Form.Control>
									: ''
								}
							</Col>
							{/* 							<Col sm="3">
								{filterData.department && filterData.department.length > 0 ?
									<Form.Control as="select" className="mb-2" name="department" onChange={(event) => onChangeDepartment(event.currentTarget)} >
										<option value="-1" >{t('structure.labels.select_department')}</option>
										{filterData.department.map((f, k) => (
											<option key={k} value={f.id} >{f.name}</option>
										))}
									</Form.Control>
									: ''
								}
							</Col> */}
							<Col>
								{filterAppliedList.structure.length > 0 &&
									<Button variant="outline-secondary" onClick={resetFilter} ><FontAwesomeIcon icon="times" /></Button>
								}
							</Col>
						</Row>
						<Row>
							<Col>
								{loading ? <Spinner animation="border" role="status">
									<span className="sr-only">Loading...</span>
								</Spinner> : ''}
							</Col>
						</Row>
						{event_list.length > 0 ?
							<>
								<Row className="p-1 text-light">
									<Col className="bg-secondary ms-1">{t('global.labels.workarea')}</Col>
									<Col className="bg-secondary ms-1">{t('global.labels.service')}</Col>
									{contract && contract.enable_claudit_trace && (userData.isGlobalAdmin || userData.view_trace_details) ?
										<Col className="bg-secondary ms-1" >Esito intervento</Col>
										:
										<Col className="bg-secondary ms-1" sm="1">Stato</Col>
									}
									<Col className="bg-secondary ms-1" sm="1">{t('global.labels.rooms')}</Col>
									<Col className="bg-secondary ms-1">{t('global.labels.intervention')}</Col>
									<Col className="bg-secondary ms-1" sm="1">Fascia oraria</Col>
								</Row>
								{event_list.filter(d => checkFilter(d)).map((e, k) => (
									<OneListEvent
										e={e}
										key={e.id}
										k={k}
										roomtype_dict={roomtype_dict}
										today_room_dict={today_room_dict}
										one_event_status={event_status[e.id]}
										t={t}
										updateEvent={updateEvent}
									/>
								))}
							</>
							: ''
						}
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseDetails}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)

}




/////////////////////////////////////////////////////////////////////////////////////////////////////////////
const OneListEvent = ({ e, k, one_event_status, t, updateEvent }) => {
	const { contract, service_dict, roomtype_dict, structureElements } = useContext(GlobalDataContext)
	const { today_room_dict } = useContext(DashboardContext)
	const { userData } = useContext(firebaseAuth)


	const intervention_type_dict = interventionType_dict({ t })
	const [showRoom, setShowRoom] = useState(false)
	const event_intervention = e.extendedProps.intervention_data

	/* 	const filtered_room_list = (event_intervention.type === 'periodic') ? 
			event_intervention.room_list.filter(room => today_room_dict[room.id].status === 'active') 
			:
			event_intervention.room_list.filter(room =>
				filterWeekDay(getSelectedElementB(
					event_intervention.service.id, roomtype_dict[room.roomtype.id] ? roomtype_dict[room.roomtype.id].service_list : [], 'service', 'id'
				).weekdays, e.start, event_intervention.custom_weekdays, room.id)) */

	const filtered_room_list = (event_intervention.type === 'periodic' || event_intervention.type === 'requested') ?
		event_intervention.room_list?.filter(_room => _room !== undefined && today_room_dict[_room.id].status === 'active')
		:
		//	event_intervention.room_list.filter(_room => filterCurrentDayRoom(e, _room, structureElements.all_room_dict, roomtype_dict))
		event_intervention.room_list?.filter(room =>
			room !== undefined && structureElements.all_room_dict[room.id] !== undefined && 
			filterWeekDay(getSelectedElementB(
				event_intervention.service.id,
				roomtype_dict[structureElements.all_room_dict[room.id].roomtype.id] ? roomtype_dict[structureElements.all_room_dict[room.id].roomtype.id].service_list : [],
				'service',
				'id'
			).weekdays, e.start, event_intervention.custom_weekdays, room.id))

	/* if (event_intervention.service?.id === 't6TIgmYB2dBkPuWgdJiK' || event_intervention.service?.id === 'lH1iqE8JgG2SJo8Mjmsq') {
		const a = event_intervention.room_list?.filter(room => {
			console.log(event_intervention.service.name,
				getSelectedElementB(event_intervention.service.id, roomtype_dict[room.roomtype.id] ? roomtype_dict[room.roomtype.id].service_list : [], 'service', 'id'),
				event_intervention.service.id,
				roomtype_dict[room.roomtype.id],
				room.roomtype.id
			)
			console.log('service_id', event_intervention.service.id)
			console.log("RT", room.roomtype, roomtype_dict, roomtype_dict[room.roomtype.id])

			return filterWeekDay(getSelectedElementB(
				event_intervention.service.id,
				roomtype_dict[room.roomtype.id] ? roomtype_dict[room.roomtype.id].service_list : [],
				'service',
				'id'
			).weekdays, e.start, event_intervention.custom_weekdays, room.id)
		}
		)
		const b = event_intervention.room_list.filter(_room => {
			if (_room !== undefined && structureElements.all_room_dict[_room.id] !== undefined) {
				console.log(e, structureElements.all_room_dict[_room.id], roomtype_dict[structureElements.all_room_dict[_room.id].roomtype.id])
				return filterCurrentDayRoom(e, structureElements.all_room_dict[_room.id], roomtype_dict)
			} else {
				return false
			}
		}

		)

		console.log(a, b)

	} */
	if (filtered_room_list?.length > 0) {

		//	console.log(filtered_room_list, event_intervention.service.id, event_intervention.custom_weekdays)
		try {
			if (e.extendedProps.type === 'ordinary_intervention' || e.extendedProps.type === 'periodic_intervention' || e.extendedProps.type === 'requested_intervention') {

				return (
					<Row className="element_row border-bottom mb-1 pb-1">
						<Col>
							<Row>
								<Col>
									{e.extendedProps.workarea_name}
								</Col>
								<Col >
									<Row>
										<Col>
											<Badge>
												<ServiceBadge service={service_dict[getBaseObjectValue(e.extendedProps.intervention_data, 'service', 'id')]} freq={true} />
											</Badge>
										</Col>
									</Row>

									<Row>
										<Col>
											<Badge>
												<SecondarySection ev={e} intervention_data={e.extendedProps.intervention_data} long={true} />
											</Badge>
										</Col>
									</Row>
								</Col>
								{contract && contract.enable_claudit_trace && (userData.isGlobalAdmin || userData.view_trace_details) ?
									<Col>
										<InterventionStatus start={e.start} end={e.end} size={1} badge={true} />
										{one_event_status ?
											<EventStatus updateEvent={updateEvent} one_event_status={one_event_status} room_count={filtered_room_list.length} full={true} intervention={event_intervention} />
											: ''}
									</Col>
									:
									<Col sm="1">
										<Row>
											<InterventionStatus start={e.start} end={e.end} size={1} />
										</Row>
									</Col>
								}
								<Col sm="1">

									<Button className="btn-block" size="sm" variant={showRoom ? 'secondary' : 'outline-secondary'} onClick={() => setShowRoom(!showRoom)}>
										{filtered_room_list.length} {t('global.labels.rooms')}
										{filtered_room_list.filter(r => today_room_dict[r.id].status === 'suspended').length > 0 ?
											<>
												<FontAwesomeIcon icon={'hourglass'} className="ms-1 text-danger" />
											</>
											: ''}
										{filtered_room_list.filter(r => today_room_dict[r.id].status === 'decommissioned').length > 0 ?
											<FontAwesomeIcon icon={'exclamation-circle'} className="ms-1 text-dark" />
											: ''}
									</Button>
									{one_event_status &&
										<ProgressBar>
											<ProgressBar now={Object.values(one_event_status.room_done_status).filter(r => r.done).length * 100 / filtered_room_list.filter(r => today_room_dict[r.id].status === 'active').length} style={{ backgroundColor: 'green' }} />
											<ProgressBar now={Object.values(one_event_status.room_done_status).filter(r => !r.done).length * 100 / filtered_room_list.filter(r => today_room_dict[r.id].status === 'active').length} style={{ backgroundColor: 'red' }} />
										</ProgressBar>
									}
								</Col>
								<Col>
								{console.log(e.extendedProps)}
									<i>{intervention_type_dict[e.extendedProps.type].title}:</i>&nbsp;
									{e.extendedProps.intervention_data.name}
								</Col>
								<Col sm="1">
									{getTimeValue(e.start)}  - {getTimeValue(e.end)}&nbsp;
									<IsModified is_modified={e.extendedProps.is_modified} legend={true} />
								</Col>
							</Row>
							{showRoom ?
								<Row className="mb-2">
									<Card as={Col}>
										<Card.Body>
											<Row className="p-1 section_title mb-2 pb-1">
												<Col className="bg-light text-secondary ms-1" sm="6">
													<Row>
														<Col>
															<StructureGroupIcon level={0} key={'level0'} />
														</Col>
														<Col>
															<StructureGroupIcon level={1} key={'level1'} />
														</Col>
														<Col>
															<StructureGroupIcon level={2} key={'level2'} />
														</Col>
														<Col>
															<StructureGroupIcon level={3} key={'level3'} />
														</Col>
													</Row>
												</Col>
												<Col className="bg-light text-secondary ms-1">
													{t('global.labels.room')}
												</Col>
												<Col className="bg-light text-secondary ms-1">
													<Row>
														<Col>
															Stato
														</Col>
														<Col>
															Matricola
														</Col>

													</Row>

												</Col>

											</Row>
											<Row>
												<EventRoomList room_list={filtered_room_list} roomtype_dict={roomtype_dict} today_room_dict={today_room_dict} one_event_status={one_event_status} intervention_data={e.extendedProps.intervention_data} start={e.start} />
											</Row>
										</Card.Body>
									</Card>
								</Row>
								: ''}
						</Col>
					</Row>
				)

			} else {
				return (
					<Row>
						<Col>
							{getTimeValue(e.start)}  - {getTimeValue(e.end)}
						</Col>
					</Row>
				)
			}

		} catch (err) {
			console.log("----ERR",err)
			return (
				<Row key={k}>
					<Col>
					</Col>
				</Row>

			)
		}
	} else {
		return (<></>)

	}
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////
const InterventionStatus = ({ start, end, size, badge }) => {
	const { now } = useContext(GlobalDataContext)

	if (myTimestampToDate(start) <= now && myTimestampToDate(end) > now) { // doing
		return (
			<OverlayTrigger
				placement="right"
				delay={popoverTiming}
				overlay={<Tooltip id="button-tooltip" >
					In esecuzione
				</Tooltip>}
			>
				{badge ?
					<MyBadge colorRgb={execution_color.doing }>
						&nbsp;
						&nbsp;
					</MyBadge>
					:
					<Col sm={size} style={{ backgroundColor: execution_color.doing }}>
						&nbsp;
					</Col>
				}
			</OverlayTrigger>
		)
	} else if (myTimestampToDate(end) > now) { //undone
		return (
			<OverlayTrigger
				placement="right"
				delay={popoverTiming}
				overlay={<Tooltip id="button-tooltip">
					Non ancora eseguito
				</Tooltip>}
			>
				{badge ?
					<MyBadge sm={size} colorRgb={execution_color.undone }>
						&nbsp;
						&nbsp;
					</MyBadge>
					:
					<Col sm={size} style={{ backgroundColor: execution_color.undone }}>
						&nbsp;
					</Col>
				}
			</OverlayTrigger>
		)
	} else {//done
		return (
			<OverlayTrigger
				placement="right"
				delay={popoverTiming}
				overlay={<Tooltip id="button-tooltip">
					Eseguito
				</Tooltip>}
			>
				{badge ?
					<MyBadge sm={size} colorRgb={execution_color.done }>
						&nbsp;
						&nbsp;
					</MyBadge>
					:
					<Col sm={size} style={{ backgroundColor: execution_color.done }}>
						&nbsp;
					</Col>
				}
			</OverlayTrigger>
		)

	}

}




/////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const EventStatus = ({ one_event_status, room_count, full, intervention, updateEvent }) => {
	const { now } = useContext(GlobalDataContext)
	const { userData } = useContext(firebaseAuth)
	const [showValidation, setShowValidation] = useState(false)
	const [temp_validation, setTempValidation] = useState()
	const end_validation = addMinutes(myTimestampToDate(one_event_status.end), intervention.max_check_delay)
	const Validation = ({ validate_status }) => {
		if (validate_status === 'true') {
			return (<Badge bg="success"><FontAwesomeIcon icon="check" /> Conforme</Badge>)
		} else if (validate_status === 'false') {
			return (<Badge bg="danger"><FontAwesomeIcon icon="times" /> Non conforme</Badge>)
		} else {
			if (end_validation >= now) {
				return (
					<>
						<Button size="sm" variant="warning" onClick={() => setShowValidation(true)}><FontAwesomeIcon icon="question-circle" /> Da valutare (entro le ore {format(end_validation, 'HH:mm')})</Button>
					</>
				)
			} else {
				return ''
			}
		}
	}


	const undoValidation = () => {
		setTempValidation()
		setShowValidation(false)
	}
	const saveValidation = () => {
		updateEvent(one_event_status, temp_validation)
		setShowValidation(false)

	}
	return (
		<>
			<FontAwesomeIcon icon="check" />&nbsp;
			Intervento eseguito
			{/* 	
			(
			{format(myTimestampToDate(one_event_status.start), 'HH:mm', { locale: it })}&nbsp;-&nbsp;
			{format(myTimestampToDate(one_event_status.end), 'HH:mm', { locale: it })}
			)
 */}
			{one_event_status.validate_status &&
				<Validation validate_status={one_event_status.validate_status} />
			}
			<br />
			{Object.values(one_event_status.room_done_status).filter(r => r.done).length !== room_count &&
				<>
					{Object.values(one_event_status.room_done_status).filter(r => r.done).length} locali eseguiti
					&nbsp;
					{Object.values(one_event_status.room_done_status).filter(r => !r.done).length} locali non eseguiti
				</>}


			{full && one_event_status.validate_status === 'delayed' &&
				<Modal show={showValidation} size="sm" backdrop="static" className="bg-secondary" animation={false}>
					<Modal.Header>
						<Modal.Title className="text-primary">
							Validazione servizio (da effettuare entro le ore {format(end_validation, 'HH:mm')})
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container fluid>
							<Row>
								<Col className="text-center">
									<Button variant={temp_validation === 'true' ? 'success' : "outline-success"} onClick={() => setTempValidation('true')}><FontAwesomeIcon icon="check" /> Conforme</Button>
								</Col>
								<Col className="text-center">
									<Button variant={temp_validation === 'false' ? 'danger' : "outline-danger"} onClick={() => setTempValidation('false')}><FontAwesomeIcon icon="times" /> Non conforme</Button>
								</Col>
							</Row>
						</Container>
					</Modal.Body>
					<Modal.Footer>
						{temp_validation === undefined ?
							<Button disabled={temp_validation === undefined} onClick={saveValidation} variant="outline-primary"><FontAwesomeIcon icon="check" /> Conferma</Button>
							:
							<Button onClick={saveValidation} variant="primary"><FontAwesomeIcon icon="check" /> Salva</Button>
						}
						<Button onClick={undoValidation} variant="outline-secondary"><FontAwesomeIcon icon="times" /> Annulla</Button>
					</Modal.Footer>
				</Modal>
			}

		</>
	)


}




// --------------------------------------------------------
export const filterCurrentDayRoom = (_event, room, roomtype_dict) => {
	if (room){

	const wd = _event.extendedProps.intervention_data.custom_weekdays && _event.extendedProps.intervention_data.custom_weekdays[room?.id]
		? _event.extendedProps.intervention_data.custom_weekdays[room.id]
		: getSelectedElementB(_event.extendedProps.intervention_data.service.id, roomtype_dict[room.roomtype.id].service_list, 'service', 'id').weekdays

	if (wd) {
		return wd.indexOf(getDay(_event.start).toString()) !== -1
	} else {
		//return true
		//		console.log("----ROOM", room.name, wd)
		return false
	}
	}else{
		return false
	}
}

