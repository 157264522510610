import React, { useState, useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Modal, Container, Row, Col, Form, Button, Card, ButtonGroup, Spinner, OverlayTrigger, Popover, ProgressBar, Tooltip } from 'react-bootstrap';
import { differenceInMinutes, format } from 'date-fns'
import { useReactToPrint } from 'react-to-print';

import LocaleContext from '../contexts/locale.js'
import DomainContext from '../contexts/domainContext'
import GlobalDataContext from '../contexts/globaldataContext.js';

import { checkMode_dict, popoverTiming } from "../constants/global_definitions"
import { myTimestampToDate, getSelectedElementB, getFloatNumber, propComparatorDate, getElementPreview, getTimeStr } from '../../util/ui_utils.js';
import { RiskareaBadge, RoomInfoBadge, ServiceBadge, ResultBar, } from "../StructureGraphicElements"

import AlertSection from "../AlertSection.js"
import { ModalProcessCheck } from "../ModalProcessCheck"
import { ServiceCheckPreview } from "../CheckPreview"
import { AnalysisResultData, AnalysisProcessData } from "../AnalysisElement"

import { getInterventionById } from '../../Firebase2/Storage2/dbIntervention'
import { getGroupCheckResults } from "../FirebaseApi/CheckResultProxy"

import firebase from 'firebase/compat/app'
import { PlanChart } from '../page_sections/homepage_check_section.js';
import PrintFooter from '../generic/PrintFooter.js';

import { editSamplingParam } from '../../Firebase2/Storage2/dbSamplingPlan.js';
import { getSecondaryCheckResult } from '../../Firebase2/Storage2/dbCheckResults.js';



let intervention_dict = {}

////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalArchiveChecks = ({ is_new, item, controlgroup, control_mode, canWrite, ui_list, t, i, check_type, nc, isAdmin }) => {






	const { domain, domain_data } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const { contractId, contract, structureElements  } = useContext(GlobalDataContext)


	const componentRef = useRef();
	const childRefAlert = useRef()
	const [loading, setLoading] = useState(true)

	const [showPlanEdit, setShowPlanEdit] = useState(false)
	const [editedElement, setEditedElement] = useState(false)
	const [curPlan, setCurPlan] = useState(item)
	const [archive_list, setArchiveList] = useState([])
	const [complementaryData, setComplementaryData] = useState({})
	const [nc_intervention_dict, setInterventionDict] = useState({})
	const [isSaving, setIsSaving] = useState(false)


	const check_mode_dict = checkMode_dict({ t })
	const handleShowEditPlan = async () => {
		setCurPlan(item)
		initArchive(item)
		setShowPlanEdit(true)

	}


	const handleClosePlan = () => {
		setShowPlanEdit(false)
	}


	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		pageStyle: pageStyle,
		documentTitle: `Analisi controlli: ${check_mode_dict[control_mode].label} (${format(myTimestampToDate(curPlan.startDate), 'dd/MM/yyyy')} - ${format(myTimestampToDate(curPlan.endDate), 'dd/MM/yyyy')})
						${controlgroup.name}`,
	});


	const handleSavePlan = async () => {
	}

	const onChangeHandlerPlan = (el) => {
		console.log(el)
		let { name, value, type } = el
		if (type === 'radio') {
			if (value === 'true') {
				value = true
			} else if (value === 'false') {
				value = false
			}
		} else if (type === 'checkbox') {
			let oldcheck = curPlan[name]
			if (el.checked) {
				if (oldcheck.indexOf(value) < 0) {
					oldcheck.push(value)
				}
			} else {
				if (oldcheck.indexOf(value) >= 0) {
					oldcheck.splice(oldcheck.indexOf(value), 1)
				}
			}
			value = oldcheck
		}
		console.log(name, value)
		setEditedElement(true)
	}


	const initArchive = async (item) => {
		setLoading(true)
		const res_data = await getGroupCheckResults({ DomainId: domain, ContractId: contractId, GroupId: controlgroup.id, ControlModeType: control_mode + "_" + check_type, FromDate: item.startDate, ToDate: item.endDate })
		if (nc) {
			setArchiveList(res_data.checkResultList.filter(a => getFloatNumber(a.lqr) < getFloatNumber(a.lqa)).sort(propComparatorDate('end_check')))
		} else {
			let lqa_sum = 0
			let lqr_sum = 0
			let check_done = 0
			let failed_check = 0
			let ra_dict = {}
			let controller_dict = {}
			for (const a of res_data.checkResultList) {
				if (a.timing_validation && (a.timing_validation === 'post' || a.timing_validation === 'pre')) {

				} else {
					lqa_sum += getFloatNumber(a.lqa)
					lqr_sum += getFloatNumber(a.lqr)
					check_done += 1
					console.log("ROOM_ID", a.room_id, a)
					if (a.room_id){

					const ra_id = structureElements.all_room_dict[a.room_id]['risk_area'].Id
					let _average_lqr_ra = ra_dict[ra_id] ? ra_dict[ra_id].average_lqr || 0 : 0
					let _average_lqa_ra = ra_dict[ra_id] ? ra_dict[ra_id].average_lqa || 0 : 0
					let _sum_lqr_ra = (ra_dict[ra_id]?ra_dict[ra_id].sum_lqr || 0: 0) + a.lqr
					let _sum_lqa_ra = (ra_dict[ra_id]?ra_dict[ra_id].sum_lqa || 0: 0) + a.lqa
					if (check_done >0 && ra_dict[ra_id] ) {
						_average_lqr_ra = ((_average_lqr_ra* ra_dict[ra_id].check_done) + a.lqr) / (ra_dict[ra_id].check_done + 1)
						_average_lqa_ra = ((_average_lqa_ra * ra_dict[ra_id].check_done) + a.lqa) / (ra_dict[ra_id].check_done + 1)
					} else {
						_average_lqr_ra = a.lqr
						_average_lqa_ra = a.lqa
					}
					if (!ra_dict[ra_id]){
						ra_dict[ra_id] = {}
					}
					ra_dict[ra_id]['check_done'] = ra_dict[ra_id].check_done ? ra_dict[ra_id].check_done + 1 : 1
					ra_dict[ra_id]['average_lqr'] = _average_lqr_ra
					ra_dict[ra_id]['average_lqa'] = _average_lqa_ra
					ra_dict[ra_id]['sum_lqr'] = _sum_lqr_ra
					ra_dict[ra_id]['sum_lqa'] = _sum_lqa_ra
					}
					if (a.lqr< a.lqa){
						failed_check += 1
					}
				}
				const duration = getCheckMinutes(a.check_timing)
				for (const c of a.member_list) {
					if (controller_dict[c.id]) {
						controller_dict[c.id].duration = controller_dict[c.id].duration + duration
						controller_dict[c.id].count += 1
					} else {
						controller_dict[c.id] = {
							id: c.id, name: c.name, duration: duration,
							count: 1
						}
					}
				}
			}
			console.log("COMPLEMENTARY DATA", lqr_sum, lqa_sum, check_done, failed_check )
			if (lqr_sum === 0){
				setComplementaryData({ lqr_sum, lqa_sum, clean_index: 0, controller_dict, total_data: {...curPlan.total_data, check_done, failed_check} })

			}else{
				setComplementaryData({ lqr_sum, lqa_sum, clean_index: lqr_sum / lqa_sum, controller_dict, total_data: {...curPlan.total_data, check_done, failed_check} })

			}
			setArchiveList(res_data.checkResultList.sort(propComparatorDate('end_check')))
		}
		setLoading(false)
	}



	useEffect(() => {
		//console.log("nc", nc_intervention_dict)

	}, [nc_intervention_dict])




	const doReconcile = async () => {
		setIsSaving(true)
		let return_data = await editSamplingParam(domain, contractId, curPlan.id, {
			...curPlan, total_data: {
				...curPlan.total_data,
				...complementaryData.total_data,
				sum_lqr: complementaryData.lqr_sum,
				sum_lqa: complementaryData.lqa_sum,
				clean_index: complementaryData.clean_index
			}
		})
		setIsSaving(false)
	}



	return (
		<>

			{nc ?
				<Button className="me-1" variant="outline-danger" size="sm" onClick={() => handleShowEditPlan()}>Registro N.C.</Button>
				:
				<Button className="me-1" variant="info" size="sm" onClick={() => handleShowEditPlan()}><FontAwesomeIcon icon="eye" /> </Button>
			}

			<Modal show={showPlanEdit} onHide={handleClosePlan} fullscreen bsclass="full-modal" backdrop="static" scrollable={true}>
				<AlertSection ref={childRefAlert} />
				<Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
					<Modal.Title>
						<FontAwesomeIcon icon="list-ol" />
						&nbsp;
						{nc ?
							<span className="text-danger">{t('checks.labels.archive_nc')}</span>
							:
							<span className="text-info">{t('checks.labels.archive_checks')}</span>
						}&nbsp;
						{check_type === 'results' ? t('global.pages.resultscheck') : t('global.pages.processcheck')}
						&nbsp;-&nbsp;
						{check_mode_dict[control_mode].label}
						&nbsp;({format(myTimestampToDate(curPlan.startDate), 'dd/MM/yyyy')} - {format(myTimestampToDate(curPlan.endDate), 'dd/MM/yyyy')})
						{controlgroup.name}&nbsp;
					</Modal.Title>
				</Modal.Header>
				<Modal.Body ref={componentRef}>
					<style type="text/css" media="print">{"\
  @page {\ size: portrait;\ }\
"}</style>
					<Row className="printonly_visible mb-2">
						<Col xs="2">
							<img
								src={domain !== 'admin' && (domain_data && domain_data.logo) ? domain_data.logo : "./Logo.Claudit.registrato.png"}
								height="40"
								className="d-inline-block align-center"
								alt={domain_data && domain_data.logo ? domain_data.name : "Claudit"}
							/>
						</Col>
						<Col>
							&nbsp;
							{nc ?
								<span className="text-danger">{t('checks.labels.archive_nc')}</span>
								:
								<span className="text-info">{t('checks.labels.archive_checks')}</span>
							}&nbsp;
							{check_type === 'results' ? t('global.pages.resultscheck') : t('global.pages.processcheck')}
							&nbsp;-&nbsp;
							{check_mode_dict[control_mode].label}
							&nbsp;({format(myTimestampToDate(curPlan.startDate), 'dd/MM/yyyy')} - {format(myTimestampToDate(curPlan.endDate), 'dd/MM/yyyy')})
							{controlgroup.name}&nbsp;

						</Col>
						<Col xs="2">
							{getElementPreview(contract, '40px')}
						</Col>
					</Row>
					{loading ?
						<Spinner animation="border" role="status">
							<span className="sr-only">Loading...</span>
						</Spinner>
						:
						<Container fluid>
							{archive_list.length > 0 ?
								<>
									{complementaryData && complementaryData.clean_index &&
										<Row>
											<Col sm="2">
												IPc
											</Col>
											<Col>
												{complementaryData.clean_index.toFixed(2)}
											</Col>
										</Row>
									}
									<Row>
										<Col className="bg-info p-1 text-light">
											Info piano di campionamento
										</Col>
									</Row>
									<Row>
										<Col sm="2">
											Modalità di controllo
										</Col>
										<Col>
											{contract.control_validation_mode === 'use_risk_area' ?
												<>Area di rischio</>
												:
												<>Globale</>
											}
										</Col>
										<Col>
										</Col>
									</Row>
									<Row className="pb-2">
										{check_type === 'results' ?
											<Col className="bg-secondary text-light ms-1 font-weight-bold">{t('global.labels.room')}</Col>
											: <></>}
										<Col className="bg-secondary text-light ms-1" >{t('global.labels.intervention')} | {t('global.labels.service')}</Col>
										<Col className="bg-secondary text-light ms-1" >{t('global.labels.date')}</Col>
										<Col className="bg-secondary text-light ms-1" >{t('global.labels.results')}</Col>
										<Col className="bg-secondary text-light ms-1" >LQR/LQA</Col>
										<Col className="bg-secondary text-light ms-1" ></Col>
									</Row>

									{archive_list.map((a, k) => (
										<OneResult index={k} a={a} key={k} ui_list={ui_list} t={t} check_type={check_type} setInterventionDict={setInterventionDict} intervention_dict={intervention_dict} group_id={item.controlgroup_id} control_mode={item.control_mode} />
									))}
								</>
								:
								<i>{t('checks.messages.no_check')}</i>}
							{check_type === 'results' ?
								<AnalysisResultData archive_list={archive_list} ui_list={ui_list} complementaryData={complementaryData}/>
								:
								<AnalysisProcessData intervention_dict={nc_intervention_dict} archive_list={archive_list} ui_list={ui_list} t={t} />
							}
							{curPlan.total_data && curPlan.total_data.check_done > 0 ?
								<>
									<Row>
										<Col className="bg-info p-1 text-light">Report controlli eseguiti
										</Col>
									</Row>
									<Row>
										<Col xs="5">
											{complementaryData.total_data &&
											<PlanChart data={complementaryData.total_data} />
											}
										</Col>
									</Row>
								</>
								: ''}

							{!nc &&
								<>
									<Form.Group as={Row} className="border-bottom mb-1 pb-1">
										<Form.Label column sm="2">{t('global.labels.analysisplan_note')}</Form.Label>
										<Col xs="8">
											<Form.Control as="textarea" rows={3} name="countinuous_actions" value={curPlan.countinuous_actions} onChange={(event) => onChangeHandlerPlan(event.currentTarget)} />
										</Col>
									</Form.Group>
									<Row>
										<Col className="text-info">
											N.B.: Se la numerosità minima del campione non è stata raggiunta, si consiglia l'uso di una verifica di conformità al 100%
										</Col>
									</Row>
									{complementaryData.controller_dict &&
										<>
											<Row>
												<Col className="bg-info p-1 text-light">
													Info Controllori
												</Col>
											</Row>
											<Row className='mt-1'>
												<Col className="bg-secondary text-light">
													Nome
												</Col>
												<Col className="bg-secondary text-light ms-1 ">
													Numero
												</Col>
												<Col className="bg-secondary text-light ms-1 ">
													Durata
												</Col>

											</Row>
											{Object.values(complementaryData.controller_dict).map(c => (
												<Row key={c.id} className="border-bottom mb-1 pb-1">
													<Col sm>
														{c.name}
													</Col>
													<Col>
														{c.count}
													</Col>
													<Col>
														{getTimeStr(c.duration, t)}
													</Col>

												</Row>
											))}
										</>}
								</>}

						</Container>
					}
					<PrintFooter />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClosePlan}>
						{t('modal.close')}
					</Button>
					<Button variant="info" onClick={handlePrint}>
						<FontAwesomeIcon icon="print" />  {t('modal.print')}
					</Button>
					{isAdmin && !nc &&
						<Button disabled={isSaving} variant="outline-warning" onClick={doReconcile} >
							Forza ricalcola totali
							{isSaving ?
								<Spinner size="sm" animation="border" role="status" />
								: <FontAwesomeIcon icon="save" />
							}
						</Button>
					}
					{canWrite ?
						<Button className={editedElement ? "btn-warning" : "btn-primary"} form="companyForm" onClick={handleSavePlan}>
							<FontAwesomeIcon icon="save" /> {t('modal.save')}
						</Button>
						: ''}
				</Modal.Footer>
			</Modal>
		</>
	)
}


//--------------------------------------------------
const OneResult = ({ a, index, ui_list, t, check_type, setInterventionDict, intervention_dict, group_id, control_mode }) => {
	const { domain, domain_data } = useContext(DomainContext)
	const { locale } = useContext(LocaleContext)
	const { contractId, contract, structureElements, service_dict, roomtype_dict, risk_area_dict, operation_dict } = useContext(GlobalDataContext)
	//const room = getSelectedElement(a.room_id, ui_list.room_structure_dict[structure.id], 'id')
	console.log(check_type, a, group_id)
	const room = structureElements.all_room_dict[a.room_id]
	const [intervention, setIntervention] = useState({ service: {} })
	const [counts, setCounts] = useState({})
	const [mydata, setData] = useState([1, 0, 2])
	const [options, setOptions] = useState({})
	const [secondary_list, setSecondaryList] = useState([])
	let roomtype = {}
	//		console.log("ROOM", room)
	if (room && room.roomtype) {
		roomtype = ui_list.roomtype_dict[room.roomtype.id]
	}
	const [curActivity, setCurActivity] = useState({})


	const initResult = async () => {
		let _intervention = intervention_dict[a.intervention_id]

		if (a.lqr < getFloatNumber(a.lqa)) {
			const c = await getSecondaryCheckResult(domain, contractId, group_id, control_mode + "_" + check_type, a.id)
			console.log(c)
			if (c && c.error) {
				setSecondaryList([])
			} else {
				setSecondaryList(c)
			}
		} else {
			setSecondaryList([])
		}
		if (!_intervention) {
			_intervention = await getInterventionById(domain, contractId, a.intervention_id)
			//					console.log("SET", domain, contractId, intervention_id, intervention)
			//updateIntervention(intervention)
			intervention_dict[a.intervention_id] = _intervention
			setIntervention(_intervention)
		} else {
			setIntervention(_intervention)
		}
		setInterventionDict(intervention_dict)
		setCurActivity(getSelectedElementB(_intervention?.service?.id, roomtype?.service_list, 'service', 'id'))
		//console.log("ROOM, intervention", room, _intervention)
		const res_array = Object.values(a.result_dict)
		let _counts = {}
		for (const res_a of res_array) {
			_counts[res_a] = _counts[res_a] ? _counts[res_a] + 1 : 1
		}
		setCounts(_counts)

		const series = [
			{
				name: 'Lqa',
				data: [

					{
						x: '2013',
						y: [54, 52],
						goals: [
							{
								name: 'Lqr',
								value: 65,
								strokeWidth: 10,
								strokeColor: '#775DD0'
							}
						]
					},
					{
						x: '2014',
						y: [66, 65],
						goals: [
							{
								name: 'Lqr',
								value: 65,
								strokeWidth: 10,
								strokeColor: '#775DD0'
							}
						]
					},
				]
			}
		]
		const myoption = {
			chart: {
				toolbar: { show: false },
				height: 50,
				type: 'bar'
			},
			plotOptions: {
				bar: {
					horizontal: true,
				}
			},


			colors: a.lqa < a.lqr ? ['#00ff00'] : ['#ff0000'],
			dataLabels: {
				formatter: function (val, opt) {
					const goals =
						opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
							.goals
					if (goals && goals.length) {
						return `${val} / ${goals[0].value}`
					}
					return val
				}
			},

			legend: {
				show: true,
				showForSingleSeries: true,
				customLegendItems: ['Lqr', 'Lqa'],
				markers: {
					size: 1,
					colors: ['#000000'],
					strokeWidth: 10,
					shape: 'square'
				}
			}
		}


		setData(series)
		setOptions(myoption)
	}





	useEffect(() => {
		initResult()
	}, [])






	if (check_type === 'results') {
		return (
			<Row className="element_row p-1 border-bottom">
				<Col>
				<Row>
					<Col sm="1">
				<Badge mode="info">{index+1}</Badge>
					</Col>
					<Col>
					<RiskareaBadge riskarea={risk_area_dict[room.risk_area.Id]} />
					<RoomInfoBadge parent_list={room.parent_list} />
					{room.name}
					</Col>
				</Row>
				<Row>
					<Col sm="1"></Col>
					<Col><span className="text-secondary">{roomtype_dict[room.roomtype.id].name}</span></Col>
				</Row>
				</Col>
				<Col>
					{intervention.name}<br />
					{intervention.service &&
						<ServiceBadge service={service_dict[intervention.service.id]} freq={true} />
					}
				</Col>
				<Col>
					{a.timing_creation && (a.timing_creation === 'post' || a.timing_creation === 'pre') ?
						<>

							{a.timing_validation && (a.timing_validation === 'post' || a.timing_validation === 'pre') ?
								<OverlayTrigger
									placement="right"
									delay={popoverTiming}
									overlay={<Tooltip id="button-tooltip">controllo fuori orario e non conteggiato</Tooltip>}
									message=""
								>
									<FontAwesomeIcon icon="exclamation-triangle" className="text-danger" />
								</OverlayTrigger>
								:
								<OverlayTrigger
									placement="right"
									delay={popoverTiming}
									overlay={<Tooltip id="button-tooltip">controllo creato fuori orario ma considerato in orario</Tooltip>}
									message=""
								>
									<FontAwesomeIcon icon="info" />
								</OverlayTrigger>
							}</>
						: ''}&nbsp;
					{format(myTimestampToDate(a.end_check), 'dd/MM/yyyy HH:mm')}
				</Col>
				<Col>
					<ResultBar length={Object.keys(a.result_dict).length} res_dict={counts} />
				</Col>
				<Col className="h5">
					<Badge className={a.lqr < getFloatNumber(a.lqa) ? 'bg-danger text-light' : 'bg-success'}>{a.lqr.toFixed(2)} / {a.lqa}</Badge>
					{a.lqr < getFloatNumber(a.lqa) && a.solved === true && <Badge className="bg-danger">risolto il {format(myTimestampToDate(a.solvedTimestamp), 'dd/MM/yyyy')}</Badge>}
				</Col>
				<Col>
					<ServiceCheckPreview roomtype={roomtype} activity={curActivity} ui_list={ui_list} t={t} roomType_name={room.name + " " + roomtype.name} roomType_elements={[...roomtype.furniture_list, ...roomtype.environment_element_list, ...roomtype.economic_material_list]} res_data={a} />

					{secondary_list && secondary_list.map((s, k) => (
						<ServiceCheckPreview key={k} roomtype={roomtype} activity={curActivity} ui_list={ui_list} t={t} roomType_name={room.name + " " + roomtype.name} roomType_elements={[...roomtype.furniture_list, ...roomtype.environment_element_list, ...roomtype.economic_material_list]} res_data={s} secondary />
					))}
				</Col>
			</Row>

		)
	} else {
		return (
			<Row className="element_row p-1 border-bottom">
				<Col>
					<Row>
						<Col>
							{intervention.name}
						</Col>
					</Row>
					<Row>
						<Col>
							<ServiceBadge service={service_dict[intervention?.service?.id]} freq={true} />
						</Col>
					</Row>
				</Col>
				<Col>
					{a.timing_creation && (a.timing_creation === 'post' || a.timing_creation === 'pre') ?
						<>

							{a.timing_validation && (a.timing_validation === 'post' || a.timing_validation === 'pre') ?
								<OverlayTrigger
									placement="right"
									delay={popoverTiming}
									overlay={<Tooltip id="button-tooltip">controllo fuori orario e non conteggiato</Tooltip>}
									message=""
								>
									<FontAwesomeIcon icon="exclamation-triangle" />
								</OverlayTrigger>
								:
								<OverlayTrigger
									placement="right"
									delay={popoverTiming}
									overlay={<Tooltip id="button-tooltip">controllo creato fuori orario ma considerato in orario</Tooltip>}
									message=""
								>
									<FontAwesomeIcon icon="info" />
								</OverlayTrigger>
							}</>
						: ''}&nbsp;
					{format(myTimestampToDate(a.end_check), 'dd/MM/yyyy HH:mm')}
				</Col>
				<Col>
					<ResultBar length={Object.keys(a.result_dict).length} res_dict={counts} />
				</Col>
				<Col>
					<Badge className={a.lqr < parseFloat(a.lqa.replace(/,/, '.')) ? 'bg-danger text-light' : 'bg-success'}>{a.lqr.toFixed(2)} /{a.lqa}</Badge>
				</Col>
				<Col>
					{intervention ?
						<ModalProcessCheck element={intervention} ui_list={ui_list} t={t} _preview={a.timing_type} _res_data={a} />
						: <></>}
				</Col>

			</Row>
		)
	}
}


const getCheckMinutes = (dl) => {
	return dl.reduce((a, v) => a + differenceInMinutes(myTimestampToDate(v.end), myTimestampToDate(v.start)), 0)
}



const pageStyle = `

@media all {
	.page-break {
	  display: none;
	}
  }
  
  @media print {
	html, body, .print_page {
	  width:210mm,
	  height: 297mm;
	  overflow: initial !important;
	  -webkit-print-color-adjust: exact;
	  color-adjust: exact;
	}
  }
  @media print{
	  .printonly_visible{
		  display:parent;
	  }
  }
  
  @media print {
	.page-break {
	  margin-top: 1rem;
	  display: block;
	  page-break-before: always;
	}
  }
  @media print {
	.print_data {
		font-size:0.8em;
	}
  }
  
  @page {
	size: A4;
	margin: 10mm;
  } 
`;

