import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal, Container, Row, Col, Form, Button, Spinner, Table, Badge, ProgressBar } from 'react-bootstrap';
import { useParams } from "react-router-dom"
import { translate } from 'react-polyglot'


import GlobalDataContext from '../components/contexts/globaldataContext.js';
import LocaleContext from '../components/contexts/locale.js'
import DomainContext from '../components/contexts/domainContext.js'

import { InitContractHelper, ReloadStructureElements } from "../util/contract.js"

import PageTitle from "../components/PageTitle.js"
import Footer from "../components/Footer.js"

import readXlsxFile from 'read-excel-file'
import { roomStatus_dict } from "../components/constants/global_definitions.js"

import { getSelectedElement, compareName, checkComponentPresence, getValue, getBaseObjectValue } from "../util/ui_utils.js"

import { useHistory } from "react-router-dom";





import { importFullStruct as importFullStruct2 } from '../Firebase2/Storage2/dbMassiveImport.js';
import {
    getStructureList,
    getPavillonList,
    getAllDepartmentInStructure,
    getAllFloorInStructure
} from "../Firebase2/Storage2/dbBaseCompany.js"
import { RiskareaBadge } from '../components/StructureGraphicElements.js';
import { ModalImportStructureConfirm } from '../components/modal/ModalImportStructureConfirm.js';
import { ModalImportRoomtypeAssign } from '../components/modal/ModalImportRoomAssign.js';
import { processRoomsStatus, reconcileRooms } from '../components/FirebaseApi/roomStatus_api';



const default_color = { r: 255, g: 255, b: 255, a: 255 }



let load_page = false

const ImportStructureView = ({ t, uData }) => {

    const { locale } = useContext(LocaleContext)
    const { domain } = useContext(DomainContext)
    const { contract, updateRoomtype, setStructureElements, roomtype_list, roomtype_dict, risk_area_list, risk_area_dict } = useContext(GlobalDataContext)

    const { initContract } = InitContractHelper()

    const history = useHistory()
    let { cid } = useParams();

    const [loading, setLoading] = useState(false)

    const status_dict = roomStatus_dict({ t })
    var contractId = cid


    const [itemCounter, setItemCounter] = useState(0)
    const [totalItem, setTotalItem] = useState(0)
    const [canWrite, setWritePage] = useState(false)

    const [allData, setAllData] = useState({})
    const [allFakeRoomTypeList, setAllFakeRoomTypeList] = useState([])
    const [curFakeRoomTypeName, setCurFakeRoomTypeName] = useState("")
    const [filterList, setFilterList] = useState({ roomtype: [], riskarea: [] })
    const [curFakeIndexes, setCurFakeIndexes] = useState([])
    const [curRoomType, setCurRoomType] = useState({})
    const [curRiskAreaId, setCurRiskAreaId] = useState('-1')
    const [showImporting, setShowImporting] = useState(false)
    const [showImportDone, setShowImportDone] = useState(false)
    const [unassignedRoomType, setUnassignedRoomType] = useState(0)
    const [curImportData, setCurImportData] = useState('')
    const [invalid, setInvalid] = useState(false)
    const [ui_list, setUiList] = useState({})


    const initValues = async () => {
        if (uData && (uData.isGlobalAdmin || uData.write_page.indexOf('companies') !== -1)) {
            setWritePage(true)
        }
        if (contract && domain.length > 0) {
            contractId = contract.id
            console.log("RISK AREA", risk_area_list)
            setUiList({ ...ui_list, risk_area_list, roomtype_list })
        }
    }



    useEffect(() => {
        async function loadData() {
            contractId = cid
            console.log("IMPORT reinit contract from param", contractId)
            if (contractId && contractId !== null) {
                const ret_data = await initContract(contractId)
                console.log("IMPORT init contract return", ret_data)
            }
        }
        if (!contract) {
            loadData()

        }
    }, [])


    useEffect(() => {
        console.log('IMPORT loadingcontract', contract)
        if (contract && !load_page) {
            initValues()
        }
    }, [contract])


    const handleCloseImporting = async () => {
        const structure_list = await getStructureList(domain, contract.id)
        if (structure_list.error) {
            console.log(structure_list)
        } else {
            let pavillon_list = []
            let floor_list = []
            let department_list = []
            let all_floor_dict = {}
            let all_pavillon_dict = {}
            let all_department_dict = {}
            let all_structure_dict = {}
            for (const structure of structure_list) {
                const return_value_pavillon = await getPavillonList(domain, contractId, structure.id)
                if (return_value_pavillon.error) {

                } else {
                    pavillon_list = [...pavillon_list, ...return_value_pavillon]
                }
                const return_value_floor = await getAllFloorInStructure(structure.id)
                if (return_value_floor.error) {

                } else {
                    floor_list = [...floor_list, ...return_value_floor]
                }
                const return_value_department = await getAllDepartmentInStructure(structure.id)
                if (return_value_department.error) {

                } else {
                    department_list = [...department_list, ...return_value_department]
                }
                await processRoomsStatus(domain, contractId, structure.id)
                await reconcileRooms(domain, contractId, structure.id)
            }
            all_pavillon_dict = Object.assign({}, ...pavillon_list.map((x) => ({ [x.id]: x })));
            all_floor_dict = Object.assign({}, ...floor_list.map((x) => ({ [x.id]: x })));
            all_department_dict = Object.assign({}, ...department_list.map((x) => ({ [x.id]: x })));
            all_structure_dict = Object.assign({}, ...structure_list.map((x) => ({ [x.id]: x })));
            setStructureElements({ all_structure_dict, structure_list, pavillon_list, all_pavillon_dict, floor_list, all_floor_dict, department_list, all_department_dict })
            setShowImportDone(false)
            history.push("companies");

        }
    }

    const createFakeIndexes = (tmpAllData, reference) => {

        let fList = []
        let tmpUnassignedRoomType = 0

        console.log("createFakeIndexes: ", filterList, tmpAllData)

        Object.keys(tmpAllData).map((i, k) => (

            Object.keys(tmpAllData[i].pavillons).map((i2, k2) => (

                Object.keys(tmpAllData[i].pavillons[i2].floors).map((i3, k3) => (
                    Object.keys(tmpAllData[i].pavillons[i2].floors[i3].departments).map((i4, k4) => (

                        tmpAllData[i].pavillons[i2].floors[i3].departments[i4].rooms.map((room, key) => {

                            if ((filterList.roomtype.length === 0 || filterList.roomtype.indexOf(room.roomtypeFake) >= 0)
                                //&& (curRiskAreaId === "-1" || (room.risk_area.Id && room.risk_area.Id === curRiskAreaId))) {
                                && (filterList.riskarea.length === 0 || (room.risk_area.Id && filterList.riskarea.indexOf(room.risk_area.Id) >= 0))) {
                                console.log(room.risk_area)

                                fList.push({
                                    struct: i,
                                    pavillon: i2,
                                    floor: i3,
                                    department: i4,
                                    roomID: key,
                                    room: room
                                })
                            }

                            if (Object.keys(room.roomtype).length === 0) {
                                tmpUnassignedRoomType = tmpUnassignedRoomType + 1
                            }

                        })
                    ))
                ))
            ))
        ))
        console.log(fList)
        setUnassignedRoomType(tmpUnassignedRoomType)
        return fList
    }

    useEffect(() => {
        console.log("EFFECT", allData)
        setCurFakeIndexes(createFakeIndexes(allData, curFakeRoomTypeName))
    }, [curFakeRoomTypeName, curRiskAreaId, filterList])



    const validElement = (el) => {
        let valid = true
        if (el[0].length === 0) {
            valid = valid && false
        }
        if (el[1].length === 0) {
            valid = valid && false
        }
        if (el[2].length === 0) {
            valid = valid && false
        }
        if (el[3].length === 0) {
            valid = valid && false
        }
        if (el[4].length === 0) {
            valid = valid && false
        }
        if (el[7].length === 0) {
            valid = valid && false
        }
        return valid

    }

    const onChangeHandlerMedia = async (el) => {
        console.log(el)
        let { name, value, type } = el
        console.log(el.files)
        value = el.files
        if (name !== undefined) {
            console.log(name, value)
        }

        setLoading(true)
        let tmpImportedData = {}
        let tmpFakeRoomType = []


        const imported_data = await readXlsxFile(el.files[0])
            .then((rows) => {
                // `rows` is an array of rows
                // each row being an array of cells.
                let skip_first = false

                let tmp_counter = 0
                setTotalItem(rows.length)


                var curDate = new Date();
                var lastMilliseconds = curDate.getTime();
                var curMilliseconds = lastMilliseconds


                rows.forEach(element => {
                    if (skip_first) {
                        if (validElement(element)) {

                            // struttura
                            const structureName = element[0]
                            if (!(tmpImportedData[structureName])) {
                                tmpImportedData[structureName] = {
                                    pavillons: {},
                                    mq: 0,
                                    room_count: 0,
                                    risk_area_summary: {},
                                    risk_area_summary_active: { total: { size: 0, room_count: 0 } },
                                    risk_area_summary_suspended: { total: { size: 0, room_count: 0 } },
                                    risk_area_summary_decommissioned: { total: { size: 0, room_count: 0 } },
                                }
                            }

                            // padiglione
                            const pavillonName = element[1]
                            if (!(tmpImportedData[structureName].pavillons[pavillonName])) {
                                tmpImportedData[structureName].pavillons[pavillonName] = {
                                    floors: {},
                                    mq: 0,
                                    room_count: 0,
                                    risk_area_summary: {},
                                    risk_area_summary_active: { total: { size: 0, room_count: 0 } },
                                    risk_area_summary_suspended: { total: { size: 0, room_count: 0 } },
                                    risk_area_summary_decommissioned: { total: { size: 0, room_count: 0 } },
                                }
                            }

                            // piano
                            const floorName = element[2]
                            if (!(tmpImportedData[structureName].pavillons[pavillonName].floors[floorName])) {
                                tmpImportedData[structureName].pavillons[pavillonName].floors[floorName] = {
                                    departments: {},
                                    mq: 0,
                                    room_count: 0,
                                    risk_area_summary: {},
                                    risk_area_summary_active: { total: { size: 0, room_count: 0 } },
                                    risk_area_summary_suspended: { total: { size: 0, room_count: 0 } },
                                    risk_area_summary_decommissioned: { total: { size: 0, room_count: 0 } },
                                }
                            }

                            // reparto
                            let departmentName = element[3]
                            if (departmentName === null) {
                                departmentName = "Aree Comuni"
                            }
                            if (!(tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].departments[departmentName])) {
                                tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].departments[departmentName] = {
                                    rooms: [],
                                    mq: 0,
                                    room_count: 0,
                                    risk_area_summary: {},
                                    risk_area_summary_active: { total: { size: 0, room_count: 0 } },
                                    risk_area_summary_suspended: { total: { size: 0, room_count: 0 } },
                                    risk_area_summary_decommissioned: { total: { size: 0, room_count: 0 } },
                                }
                            }

                            // ROOM
                            let RoomData = {
                                name: element[7],
                                number: element[7],
                                cdc: element[8] ? element[8] : "",
                                glass_percentage: element[9] ? element[9] : 0,
                                mq: Number(element[5]),
                                note: element[10],
                                status: getStatus(element[11]),
                                risk: element[6] + "",
                                risk_area: {},
                                roomtype: {},
                                roomtypeFake: element[4],
                                roomtypeId: ""
                            }
                            console.log("ROOMDATA", RoomData)

                            // ---- check RA reference presence
                            if (ui_list.risk_area_list) {
                                ui_list.risk_area_list.map((r, k) => {
                                    let ra = RoomData.risk
                                    ra = ra.replace(/\s\s+/g, ' ')
                                    if (ra === r.name[locale]) {
                                        RoomData.risk_area = r
                                    }
                                })
                            }
                            // update all mq
                            tmpImportedData[structureName].mq += RoomData.mq
                            tmpImportedData[structureName].room_count += 1

                            tmpImportedData[structureName].pavillons[pavillonName].mq += RoomData.mq
                            tmpImportedData[structureName].pavillons[pavillonName].room_count += 1

                            tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].mq += RoomData.mq
                            tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].room_count += 1

                            tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].departments[departmentName].mq += RoomData.mq
                            tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].departments[departmentName].room_count += 1



                            // structure: UPDATE RISK AREA
                            if (tmpImportedData[structureName].risk_area_summary[RoomData.risk_area.Id]) {
                                tmpImportedData[structureName].risk_area_summary[RoomData.risk_area.Id].size += RoomData.mq
                                tmpImportedData[structureName].risk_area_summary[RoomData.risk_area.Id].room_count += 1
                                tmpImportedData[structureName].risk_area_summary_active[RoomData.risk_area.Id].size += RoomData.mq
                                tmpImportedData[structureName].risk_area_summary_active[RoomData.risk_area.Id].room_count += 1
                            } else {
                                tmpImportedData[structureName].risk_area_summary[RoomData.risk_area.Id] = {
                                    size: RoomData.mq,
                                    room_count: 1,
                                    color: RoomData.risk_area.color || default_color
                                }
                                tmpImportedData[structureName].risk_area_summary_active[RoomData.risk_area.Id] = {
                                    size: RoomData.mq,
                                    room_count: 1,
                                    color: RoomData.risk_area.color || default_color
                                }
                            }

                            // pavillon: UPDATE RISK AREA
                            if (tmpImportedData[structureName].pavillons[pavillonName].risk_area_summary[RoomData.risk_area.Id]) {
                                tmpImportedData[structureName].pavillons[pavillonName].risk_area_summary[RoomData.risk_area.Id].size += RoomData.mq
                                tmpImportedData[structureName].pavillons[pavillonName].risk_area_summary[RoomData.risk_area.Id].room_count += 1
                                tmpImportedData[structureName].pavillons[pavillonName].risk_area_summary_active[RoomData.risk_area.Id].size += RoomData.mq
                                tmpImportedData[structureName].pavillons[pavillonName].risk_area_summary_active[RoomData.risk_area.Id].room_count += 1
                            } else {
                                tmpImportedData[structureName].pavillons[pavillonName].risk_area_summary[RoomData.risk_area.Id] = {
                                    size: RoomData.mq,
                                    room_count: 1,
                                    color: RoomData.risk_area.color || default_color
                                }
                                tmpImportedData[structureName].pavillons[pavillonName].risk_area_summary_active[RoomData.risk_area.Id] = {
                                    size: RoomData.mq,
                                    room_count: 1,
                                    color: RoomData.risk_area.color || default_color
                                }
                            }

                            // floor: UPDATE RISK AREA
                            if (tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].risk_area_summary[RoomData.risk_area.Id]) {
                                tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].risk_area_summary[RoomData.risk_area.Id].size += RoomData.mq
                                tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].risk_area_summary[RoomData.risk_area.Id].room_count += 1
                                tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].risk_area_summary_active[RoomData.risk_area.Id].size += RoomData.mq
                                tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].risk_area_summary_active[RoomData.risk_area.Id].room_count += 1
                            } else {
                                tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].risk_area_summary[RoomData.risk_area.Id] = {
                                    size: RoomData.mq,
                                    room_count: 1,
                                    color: RoomData.risk_area.color || default_color
                                }
                                tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].risk_area_summary_active[RoomData.risk_area.Id] = {
                                    size: RoomData.mq,
                                    room_count: 1,
                                    color: RoomData.risk_area.color || default_color
                                }
                            }

                            // department: UPDATE RISK AREA
                            if (tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].departments[departmentName].risk_area_summary[RoomData.risk_area.Id]) {
                                tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].departments[departmentName].risk_area_summary[RoomData.risk_area.Id].size += RoomData.mq
                                tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].departments[departmentName].risk_area_summary[RoomData.risk_area.Id].room_count += 1
                                tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].departments[departmentName].risk_area_summary_active[RoomData.risk_area.Id].size += RoomData.mq
                                tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].departments[departmentName].risk_area_summary_active[RoomData.risk_area.Id].room_count += 1
                            } else {
                                tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].departments[departmentName].risk_area_summary[RoomData.risk_area.Id] = {
                                    size: RoomData.mq,
                                    room_count: 1,
                                    color: RoomData.risk_area.color || default_color
                                }
                                tmpImportedData[structureName].pavillons[pavillonName].floors[floorName].departments[departmentName].risk_area_summary_active[RoomData.risk_area.Id] = {
                                    size: RoomData.mq,
                                    room_count: 1,
                                    color: RoomData.risk_area.color || default_color
                                }
                            }

                            // DEBUG: console.log("tmpImportedData: ", tmpImportedData)

                            // insert new FAKE ROOMTYPE if not present
                            if (!tmpFakeRoomType.includes(element[4])) {
                                tmpFakeRoomType.push(element[4])
                            }

                            // insert ROOM DATA into Main LIST
                            tmpImportedData[structureName].pavillons[pavillonName]
                                .floors[floorName]
                                .departments[departmentName]
                                .rooms.push(RoomData)

                            tmp_counter = tmp_counter + 1

                            curDate = new Date();
                            curMilliseconds = curDate.getTime();
                            if (curMilliseconds > lastMilliseconds + 250) {
                                setItemCounter(tmp_counter)
                                lastMilliseconds = curMilliseconds
                            }

                        } else {
                            setInvalid(true)
                        }
                    } else {
                        skip_first = true;
                    }
                });
                setItemCounter(tmp_counter)
                return tmpImportedData
            }).catch(error => {
                console.log("error importing data: ", error)
                return { error: error }
            })


        console.log("imported_data: ", imported_data)

        tmpFakeRoomType.sort()
        const tmpFakeRoomTypeData = tmpFakeRoomType.map(function (rt) {
            return {
                assigned: '',
                id: rt
            }
        })
        setAllFakeRoomTypeList(tmpFakeRoomTypeData)

        setAllData(imported_data)
        setCurFakeIndexes(createFakeIndexes(imported_data, ""))
        setLoading(false)


    }

    const handleFilterSelect = (target) => {
        console.log(target.value)
        console.log("handleFilterSelect: ", allData)
        setCurFakeRoomTypeName(target.value)
        if (target.value === "") {
            setCurRiskAreaId("-1")
        }
        setCurRoomType({})
    }

    const handleFilterRiskArea = (target) => {
        console.log(target.value)
        setCurRiskAreaId(target.value)
        //setCurRoomType({})
    }

    const handleRoomTypeSelect = (target) => {
        console.log(target)
        setCurRoomType(target)
    }


    const onAssignRoomType = (selRoomType) => {

        curFakeIndexes.map((indexes, key) => {
            let EditData = { ...allData }
            EditData[indexes.struct]
                .pavillons[indexes.pavillon]
                .floors[indexes.floor]
                .departments[indexes.department]
                .rooms[indexes.roomID].roomtype = selRoomType
            setAllData(EditData)
            return null
        })

        if (allData) {
            console.log("ALL DATA", allData)
            setCurFakeIndexes(createFakeIndexes(allData, curFakeRoomTypeName))
        } else {
            let tmp_curFakeRt = [...allFakeRoomTypeList]
            for (const rt of filterList.roomtype) {
                const c = checkComponentPresence({ id: rt }, tmp_curFakeRt, 'id')
                console.log(curFakeRoomTypeName, c)
                let edit_fake = { ...tmp_curFakeRt[c], assigned: selRoomType.name }
                tmp_curFakeRt.splice(c, 1, edit_fake)
                console.log("ASSIGN", tmp_curFakeRt)
            }
            setAllFakeRoomTypeList(tmp_curFakeRt)

        }
        setFilterList({ roomtype: [], riskarea: [] })
    }


    const onImportData = async (data) => {
        console.log('importing fase 1')
        setShowImporting(true)
        console.log('importing fase 2')
        const ret_data = await importFullStruct2(domain, contractId, data, itemCounter, cbOne, cbAll)
        console.log('importing fase 3')
        sessionStorage.removeItem("structure_list")

        // FIXME: RESET import page
        setItemCounter(0);
        setAllData({})
        setAllFakeRoomTypeList([])
        setCurFakeIndexes([])
        console.log('importing fase 4')
    }


    const handleToggleFilter = (i, _type) => {
        let _filter = [...filterList[_type]]
        const c = _filter.indexOf(i)
        if (c >= 0) {
            _filter.splice(c, 1)
        } else {
            _filter.push(i)
        }
        setFilterList((f) => ({ ...f, [_type]: _filter }))
    }

    const cbOne = (st) => {
        setCurImportData(st)
    }
    const cbAll = () => {
        setShowImporting(false)
        setShowImportDone(true)
    }


    return (
        <div>
            <Container className="titleStickyTop" fluid>
                <Row className="mb-2 text-start">
                    <Col sm="6">
                        <PageTitle title={t('global.pages.import_structure')} />
                    </Col>
                    <Col className="text-end">
                        <Button variant="outline-info" href="/TemplateStruttura.xlsx" download><FontAwesomeIcon icon="file-excel" /> {t('import.buttons.download_template')}</Button>
                    </Col>
                </Row>
                <Form.Group as={Row} className="border-bottom pb-1">
                    <Form.Label column sm="2" className="text-end"><FontAwesomeIcon icon="file-excel" /> {t('global.labels.media_file')}</Form.Label>
                    <Col>
                        <Form.Control type="file" name="file" label="carica file *.xlsx" accept=".xlsx" onChange={(event) => onChangeHandlerMedia(event.target)} />
                    </Col>
                    {itemCounter > 0 &&

                        <Col className="text-end">
                            <ModalImportStructureConfirm t={t} allData={allData} disabled={unassignedRoomType > 0 && !invalid} onImportData={onImportData} />
                        </Col>
                    }
                </Form.Group>


                <Row>
                    {loading ?
                        <Row>
                            <Col className="text-center">
                                <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>&nbsp;Caricamento in corso
                            </Col>
                        </Row>
                        : ''}
                </Row>
                {invalid ?
                    <Row>
                        <Col className="text-danger">
                            {t('structure.labels.incomplete_data')}
                        </Col>
                    </Row>
                    : ''}
                {itemCounter === 0 ? <></> :
                    <>
                        <Row className="mb-1">
                            <Col className="bg-secondary ms-1 text-white">
                                {t('structure.labels.roomtype_founded')}
                            </Col>
                            <Col className="bg-secondary ms-1 text-white" sm="2">

                                {contract && contract.type === 'civil' ?
                                    t('global.labels.standard_cleaning')
                                    :
                                    t('global.labels.risk_area')
                                }							&nbsp;
                            </Col>
                            <Col className="bg-secondary ms-1 text-center" sm="2">
                            </Col>
                        </Row>
                        <Form.Group as={Row} >
                            <Col>
                                {allFakeRoomTypeList.map((i, k) => (
                                    <Button className="me-1" size="sm" key={k} onClick={() => handleToggleFilter(i.id, 'roomtype')} variant={filterList.roomtype.indexOf(i.id) >= 0 ? "info" : 'outline-info'}>
                                        {i.id} {getRtStatus(curFakeIndexes.filter(r =>
                                            i.id === r.room.roomtypeFake
                                        ))}
                                    </Button>
                                ))}
                            </Col>
                            <Col sm="2">
                                {risk_area_list.map((i, k) => (
                                    <Button disabled={filterList.roomtype.length === 0} size="sm" key={k} onClick={() => handleToggleFilter(i.Id, 'riskarea')} variant={filterList.riskarea.indexOf(i.Id) >= 0 ? "info" : 'outline-info'}>
                                        <RiskareaBadge riskarea={i} />
                                    </Button>
                                ))}
                            </Col>
                            <Col sm="2">
                                <ModalImportRoomtypeAssign t={t} allData disabled={filterList.roomtype.length===0} onAssignRoomType={onAssignRoomType} canWrite={canWrite} />
                            </Col>

                        </Form.Group>
                        <Row>
                            <Col className="bg-secondary text-center text-light">
                            </Col>
                            <Col className="bg-secondary text-center text-light">
                                Elementi rilevati
                            </Col>
                            <Col className="bg-secondary text-center text-light">
                                <ProgressBar now={100 * (itemCounter - unassignedRoomType) / itemCounter} label={`${itemCounter - unassignedRoomType}/${itemCounter}`} />
                            </Col>
                        </Row>
                    </>
                }
            </Container>
            <Container fluid>


                {itemCounter === 0 ? "" :
                    <>


                        <Row>
                            <Col>
                                <Table responsive striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>Struttura</th>
                                            <th>Palazzina</th>
                                            <th>Piano</th>
                                            <th>Reparto</th>
                                            <th>Numero</th>
                                            <th>Locale</th>
                                            <th>mq</th>
                                            <th>Area di Rischio</th>
                                            <th>Centro di costo</th>
                                            <th>vetri %</th>
                                            <th>note</th>
                                            <th>stato</th>
                                            <th className="bg-info text-light">Tipologia Locale assegnata</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            curFakeIndexes.map((indexes, key) => (
                                                <tr key={indexes.struct + "_" + indexes.pavillon + "_" + indexes.floor + "_" + indexes.department + "_" + indexes.roomID}
                                                    className={Object.keys(indexes.room.roomtype).length > 0 ? "" : "text-warning"}>
                                                    <td> {indexes.struct} </td>
                                                    <td> {indexes.pavillon} </td>
                                                    <td> {indexes.floor} </td>
                                                    <td> {indexes.department} </td>
                                                    <td> {indexes.room.number} </td>
                                                    <td> {indexes.room.roomtypeFake} </td>
                                                    <td> {indexes.room.mq} </td>
                                                    <td className={Object.keys(indexes.room.risk_area).length > 0 ? "text-success" : "text-danger"}>
                                                        {Object.keys(indexes.room.risk_area).length === 0 && <FontAwesomeIcon icon="exclamation-triangle" />}
                                                        {indexes.room.risk}
                                                    </td>
                                                    <td> {indexes.room.cdc} </td>
                                                    <td> {indexes.room.glass_percentage} </td>
                                                    <td> {indexes.room.note} </td>
                                                    <td> {status_dict[indexes.room.status].label} </td>
                                                    <td> {Object.keys(indexes.room.roomtype).length > 0 ? <>{indexes.room.roomtype.name} <RiskareaBadge riskarea={risk_area_dict[getValue(indexes.room.roomtype.risk_area, 'Id')]} /></> : <><FontAwesomeIcon icon="exclamation-triangle" />Tipo di locale non assegnata!</>} </td>
                                                </tr>
                                            )
                                            )
                                        }
                                    </tbody>
                                </Table>

                            </Col>
                        </Row>
                    </>
                }


                <Modal show={showImporting} backdrop="static">
                    <Modal.Header>
                        Importazione in corso
                    </Modal.Header>
                    <Modal.Body>
                        Importazione della nuova struttura in corso.
                        <br />... {curImportData} <br />
                        <i>(La procedura può richiedere diversi minuti: si prega di attendere senza chiudere la pagina fino al termine dell'importazione)</i>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </Modal.Body>
                </Modal>
            </Container>
            <Footer count_text={loading ? <Spinner animation="border" role="status" /> : <>righe nel file: {itemCounter} &nbsp;  [ di cui {unassignedRoomType} senza Tipologia di locale]</>} />


            <Modal show={showImportDone}>
                <Modal.Body>
                    Importazione eseguita correttamente
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseImporting}>
                        {t('modal.close')}
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}

const getStatus = (st) => {
    if (st === 'S' || st === 's') {
        return 'suspended'
    } else if (st === 'D' || st === 'd') {
        return 'decommissioned'
    } else {
        return 'active'
    }

}

const getRtStatus = (rt_list) => {
    const done = rt_list.filter(r => r.room.roomtype.id).length
    const total = rt_list.length
    if (total === 0) {
        return ''
    } else if (done < total) {
        return <Badge bg="warning" text="dark">{done}/{total}</Badge>
    } else {
        return <Badge bg="success">{done}/{total}</Badge>
    }

}


const getFoundTypeStatus = (rt, list) => {
    if (rt.assigned && rt.assigned.length > 0) {
        return rt.id + ' -> ' + rt.assigned
    } else {
        console.log(rt)
        return `${rt.id}`
    }
}

export default translate()(ImportStructureView)