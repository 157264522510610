export const getSystemMenu_list = (props) => {
  const t = props.t
  const contract = props.contract
  let global_menu = [
    {
      label: 'Contratto',
      icon: "/menu_icons/GestioneContratto.png",
      id: 'contracts',
      is_menu: true,
      menulist: [
        { type: "link", href: "/contractdashboard", id: "contractdashboard", icon: '', label: 'Gestione Contratti', disabled: false, outside_contract:true },
        { type: "link", href: "/home", id: "home", icon: '', label: "Dashboard", disabled: false },
      ]
    },
    {
      label: 'Home',
      icon: "/menu_icons/Home.png",
      id: 'home',
      is_menu: false,
      menulist: [
        { type: "link", href: "/homepage", id: "homepage", icon: '', label: t('global.pages.home'), disabled: false },
      ]
    },
    {
      label: t('global.pages.communications'),
      icon: "/menu_icons/Comunicazioni.png",
      id: 'communications',
      is_menu: true,
      menulist: [
        { type: "link", href: "/agenda", id: "agenda", icon: '', label: t('global.pages.agenda'), disabled: false },
        { type: "link", href: "/ticket", id: "messages", icon: '', label: t('global.pages.messages'), disabled: false },
        { type: "link", href: "/failure", id: "failure", icon: '', label: t('global.pages.failure'), disabled: false },
      ]
    },
    {
      label: t('global.pages.service'),
      icon: "/menu_icons/Servizi.png",
      id: 'proceduralguideline',
      is_menu: true,
      menulist: [
        { type: "link", href: "/services", id: "services", icon: '', label: t('global.pages.services'), disabled: false },
        { type: "link", href: "/operations", id: "operations", icon: '', label: t('global.pages.operations'), disabled: false },
        { type: "link", href: "/roomtypes", id: "room_types", icon: '', label: t('global.pages.room_type'), disabled: false },
        { type: "link", href: "/yieldsummary", id: "yield_summary", icon: '', label: t('global.pages.yield_summary'), disabled: false },
      ]
    },
    {
      label: t('global.pages.registry'),
      icon: "/menu_icons/GestioneSuperfici.png",
      id: 'surfacemanagement',
      is_menu: true,
      menulist: [
        { type: "link", href: "/companies", id: "companies", icon: '', label: t('global.pages.structures_and_centers'), disabled: false },
        { type: "link", href: "/variationregister", id: "variationregister", icon: '', label: t('global.pages.variation_register'), disabled: false },
      ]
    },
    {
      label: t('global.pages.planning'),
      icon: "/menu_icons/Programmazione.png",
      id: 'surfacemanagement',
      is_menu: true,
      menulist: [
        { type: "link", href: "/workareas", id: "workareas", icon: '', label: t('global.pages.working_area'), disabled: false },
        { type: "link", href: "/ordinaryintervention", id: "ordinaryintervention", icon: '', label: t('global.pages.ordinaryintervention'), disabled: false },
        { type: "link", href: "/periodicintervention", id: "programmedintervention", icon: '', label: t('global.pages.programmedintervention'), disabled: false },
        { type: "link", href: "/workflows", id: "workflows", icon: '', label: t('global.pages.workflow'), disabled: false },
        { type: "link", href: "/planning", id: "planning", icon: '', label: t('global.pages.agenda_planning'), disabled: false },
        { type: "divider" },
        { type: "link", href: "/workshift", id: "workshift", icon: '', label: t('global.pages.workshift'), disabled: false },
        { type: "link", href: "/operatorassignment", id: "operatorassignment", icon: '', label: t('global.pages.operator_assignment'), disabled: false },
        { type: "divider" },
        { type: "link", href: "/executionreport", id: "executionreport", icon: '', label: t('global.pages.executionreport'), disabled: contract && !contract.enable_claudit_trace },
      ]
    },
    {
      label: t('global.pages.organization'),
      icon: "/menu_icons/Icone_Organigramma.png",
      id: 'organization',
      is_menu: true,
      menulist: [
        { type: "link", href: "/organizationchart", id: "organizationchart", icon: '', label: t('global.pages.organizationcharts'), disabled: false },
        //{ type: "link", href: "/workcontract", id: "workcontract", icon: '', label: t('navs.settings.workcontract_short'), disabled: false },
        { type: "link", href: "/employes", id: "employes", icon: '', label: t('navs.registries.employes'), disabled: false },
        //{ type: "divider" },
      //  { type: "link", href: "/training", id: "training", icon: '', label: t('navs.settings.training'), disabled: false },
      //  { type: "link", href: "/trainingplanning", id: "trainingplanning", icon: '', label: t('global.pages.trainingplanning'), disabled: false },
        //{ type: "link", href: "/trainingreport", id: "trainingreport", icon: '', label: t('global.pages.trainingreport'), disabled: false },
      ]
    },
    {
      label: t('global.pages.checks'),
      icon: "/menu_icons/Verifiche.png",
      id: 'checks',
      is_menu: true,
      menulist: [
        { type: "link", href: "/checkparamsdefinitions", id: "checkparamsdefinitions", icon: '', label: t('global.pages.checkparamsdefinitions'), disabled: false },
        { type: "link", href: "/controlgroup", id: "congrolgroup", icon: '', label: t('global.pages.controlgroups'), disabled: false },
        { type: "link", href: "/checkplanning", id: "checkplanning", icon: '', label: t('global.pages.checkplanning'), disabled: false },
        { type: "link", href: "/resultcheck", id: "resultcheck", icon: '', label: t('global.pages.resultscheck'), disabled: false },
        { type: "link", href: "/processcheck", id: "processcheck", icon: '', label: t('global.pages.proocesscheck'), disabled: false },
        { type: "link", href: "/microbiologicalcheck", id: "microbiologicalcheck", icon: '', label: t('global.pages.microbiologicalcheck'), disabled: false },
        { type: "divider" },
        { type: "link", href: "/customersatisfaction", id: "customersatisfaction", icon: '', label: t('global.pages.customersatisfaction'), disabled: false },
       ]
    },
    {
      label: t('global.pages.documents'),
      icon: "/menu_icons/Documenti.png",
      id: 'documents',
      is_menu: true,
      menulist: [
        { type: "link", href: "/documentscontract", id: "documentscontract", icon: '', label: t('global.pages.contractdocument_management'), disabled: false },
        { type: "link", href: "/documentsstructure", id: "documentsstructure", icon: '', label: t('global.pages.structuredocument_management'), disabled: false },
        { type: "link", href: "/documentsstorage", id: "documentsstorage", icon: '', label: t('global.pages.storagedocument_management'), disabled: false },
        { type: "divider" },
        { type: "link", href: "/documentssystem", id: "documentssystem", icon: '', label: t('global.pages.systemdocument_management'), disabled: false },
      ]
    },
    {
      label: t('global.pages.reports'),
      icon: "/menu_icons/Report.png",
      id: 'reports',
      is_menu: true,
      menulist: [
         { type: "link", href: "/noncomplianceregistry", id: "noncomplianceregistry", icon: '', label: t('global.pages.non_compliance_registry'), disabled: false },
        { type: "link", href: "/economicreport", id: "economicreport", icon: '', label: t('global.pages.economicreport'), disabled: false },
        { type: "link", href: "/interventionreport", id: "interventionreport", icon: '', label: t('global.pages.interventionreport'), disabled: false },
      ]
    },
    {
      label: t('global.pages.settings'),
      icon: "/menu_icons/Impostazioni.png",
      id: 'settings',
      is_menu: true,
      menulist: [
        //{ type: "link", href: "/definitions", id: "definitions", icon: '', label: t('navs.settings.base_objects'), disabled: false },
        //        { type: "link", href: "/costcenter",  id:"costcenter", icon: '', label: t('navs.registries.costcenter'), disabled: true },
        //{ type: "header", icon: 'anagrafiche', id: "", label: t('global.pages.registry') },
        //{ type: "divider" },
//        { type: "link", href: "/cleaningcompanies", id: "cleaningcompanies", icon: '', label: t('navs.registries.cleaningcompanies'), disabled: false },
        //{ type: "link", href: "/trainer", id: "trainer", icon: '', label: t('navs.global.trainer'), disabled: false },
        { type: "link", href: "/controltool", id: "controltool", icon: '', label: t('navs.global.controltool'), disabled: false },
        { type: "link", href: "/dpi", id: "dpi", icon: '', label: t('navs.registries.dpi'), disabled: false },
       // { type: "link", href: "/clothing", id: "clothing", icon: '', label: t('navs.registries.clothing'), disabled: false },
        { type: "link", href: "/machines", id: "machines", icon: '', label: t('navs.registries.machines'), disabled: false },
        { type: "link", href: "/carts", id: "carts", icon: '', label: t('navs.registries.carts'), disabled: false },
        { type: "link", href: "/equipments", id: "equipments", icon: '', label: t('navs.registries.equipments'), disabled: false },
        { type: "link", href: "/products", id: "products", icon: '', label: t('navs.registries.products'), disabled: false },
        { type: "link", href: "/cleaningmaterials", id: "cleaningmaterials", icon: '', label: t('navs.registries.cleaningmaterials'), disabled: false },
        { type: "link", href: "/dilutionsystem", id: "dilutionsystem", icon: '', label: t('navs.registries.dilutionsystem'), disabled: false },
       // { type: "link", href: "/suppliers", id: "suppliers", icon: '', label: t('navs.registries.suppliers'), disabled: false },
        { type: "divider" },
        { type: "link", href: "/failuretype", id: "failuretype", icon: '', label: t('navs.registries.failuretype'), disabled: false },
      ]
    },
  ]
  //console.log(props)
  if (props.config || (props.page_permission && props.page_permission.isAdmin)){
    return global_menu
  }else if (props.page_permission && props.page_permission.read_page) {
    let new_menu = []
    for (let p = 0; p < global_menu.length; p++) {
      const menu = global_menu[p].menulist.filter(m => m.type === "divider" || m.type === 'header' || (m.type === 'link' && (props.page_permission.write_page.indexOf(m.id) !== -1 || props.page_permission.read_page.indexOf(m.id) !== -1)))
      if (menu.filter(m => m.type === "link").length === 0) {
        menu.hide = true
      } else {
        new_menu.push({...global_menu[p], menulist: menu})
      }
    }
    return new_menu
  } else {
    return []
  }
}

export const section_dict = (props) => {
  const mylist = {

  }
  return mylist

}