import React, { useState, useContext, useEffect, useRef, useLayoutEffect } from 'react';
import { Modal, Container, Image, ProgressBar, ButtonGroup, Row, Col, Form, Badge, Button, Card, InputGroup, Spinner, Nav, Tab, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseObjectView from '../../pages/BaseObjectView';
import RoomTypeDomainImageView from '../../pages/RoomTypeDomainImageView';


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const ModalDomainConfig = ({ t, uData }) => {
    const [showEditUsers, setShowUsers] = useState(false);
    const handleCloseUsers = () => setShowUsers(false);
    const [editedElement, setEditedElement] = useState(false)

    const [curSection, setCurSection] = useState('roomtype_image')
    return (
        <>
            <Button variant="outline-info" onClick={() => setShowUsers(true)}>
                <FontAwesomeIcon icon="cogs" /></Button>
            <Modal show={showEditUsers} onHide={handleCloseUsers} fullscreen backdrop="static" className="bg-secondary" scrollable={true}>
                <Modal.Header closeButton className={editedElement ? "bg-warning" : ""}>
                    <Modal.Title>
                        Gestione configurazione
                        <ButtonGroup>
                            <Button size="sm" onClick={() => setCurSection('roomtype_image')} variant={curSection === 'roomtype_image' ? 'info' : 'outline-secondary'}>Immagini Tipologia locali</Button>
                            <Button size="sm" onClick={() => setCurSection('baseobject')} variant={curSection === 'baseobject' ? 'info' : 'outline-secondary'}>Oggetti base</Button>
{/*                             <Button size="sm" onClick={() => setCurSection('documents')} variant={curSection === 'documents' ? 'info' : 'outline-secondary'}>Documenti</Button>
 */}                        </ButtonGroup>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        {curSection === 'baseobject' &&
                            <BaseObjectView t={t} uData={uData} />
                        }
                        {curSection === 'roomtype_image' &&
                            <RoomTypeDomainImageView t={t} uData={uData} />
                        }
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseUsers}>
                        {t('modal.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}