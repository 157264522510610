import React, { useState, useEffect, useContext, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Row, Col, Button, ButtonGroup, Badge, Spinner, Tooltip, OverlayTrigger, Modal, Container, Card } from 'react-bootstrap';
import { translate } from 'react-polyglot'

import { appRole_dict, } from "../components/constants/global_definitions"

import { checkComponentPresence, propComparator } from "../util/ui_utils.js"

import ModalUser from '../components/ModalUser'


import { editUserData, getUserList } from '../Firebase2/Storage2/dbUsers';
import { getContractListExpanded } from '../Firebase2/Storage2/dbContract';
import { disableUser, removeUserFromAllDomain, removeUserFromDomain } from '../components/FirebaseApi/user_api'
import { firebaseAuth } from '../provider/AuthProvider';
import { OneUser } from './constants/one_user';
import DeleteconfirmationSection from './DeleteConfirmationSection';
const defaultfilter = { contract: [], contractgroups: [], systemgroups: [], disabled: true }



function ElementFormUsers({ t, domain, ui_list, contract_list, not_contract }) {
	console.log("USER DOMAIN", domain, not_contract)
	//	const { domain } = useContext(DomainContext)


	const app_roles = appRole_dict({ t })
	const [user_list, setUserList] = useState([])
	const [c_list, setContractList] = useState(contract_list)
	const [loading, setLoading] = useState(false)

	const [filterAppliedList, setFilterAppliedList] = useState(defaultfilter)

	const { userData } = useContext(firebaseAuth)

	const childRefDelete = useRef()


	const initValues = async () => {
		setLoading(true)
		let newelement_list = []
		if (domain && domain.length > 0) {
			newelement_list = await getUserList(domain)
			newelement_list = newelement_list.filter(u => !u.is_deleted).sort(propComparator('displayName'))
			if (!contract_list) {
				let newc_list = await getContractListExpanded(domain)
				newc_list = newc_list.sort(propComparator('name'))
				const archived = newc_list.filter(c => c.is_archived)
				const not_archived = newc_list.filter(c => !c.is_archived)
				setContractList([...not_archived, ...archived])
			}
		}
		if (userData && (userData.isGlobalAdmin)) {
			setUserList(newelement_list)
		} else {
			setUserList(newelement_list.filter(u => !u.debug_user))
		}

		console.log("source user_list", newelement_list)
		setLoading(false)
	}


	useEffect(() => {
		initValues()
	}, [])



	const handleDelete = (el) => {
		console.log(el)
		const cb = async () => {

			//		const ret_value = await removeUserFromDomain(el.id, domain)
			const ret_value = await editUserData(domain, el.id, { ...el, is_deleted: true })
			const a = await removeUserFromAllDomain(el.id)
			const b = await disableUser(el.id, true)
			console.log(ret_value)
			let editlist = [...user_list]
			const c = checkComponentPresence(el, user_list, 'id')
			editlist.splice(c, 1)
			setUserList(editlist)
		}
		childRefDelete.current.confirmDelete("Rimuovi utente", `Sei sicuro di voler eliminare l’utente <b>${el.displayName}</b> ?<br/>(verrà cancellato in modo permanente)`, cb)
	}



	return (
		<>
			<Row className="mb-2">
				{!loading && !not_contract &&
					<>
						<Col>
							<ModalFilter t={t} ui_list={ui_list} filterAppliedList={filterAppliedList} setFilterAppliedList={setFilterAppliedList} defaultfilter={defaultfilter} contract_list={c_list} not_contract={not_contract} />
						</Col>
						<Col>
							{user_list.length}
						</Col>
					</>
				}
				<Col className="text-end">
					<ModalUser is_new={true} t={t} item_list={user_list} domain={domain} ui_list={ui_list} setItem={setUserList} setParentList={setUserList} contract_list={c_list} not_contract={not_contract} />
				</Col>
			</Row>
			<DeleteconfirmationSection ref={childRefDelete} />
			{loading ?
				<Row>
					<Col>
						<Spinner animation="border" role="status">
							<span className="sr-only">Loading...</span>
						</Spinner>
					</Col>
				</Row>
				:
				<>
					{user_list && user_list.length > 0 ?
						<>
							<Row className="mb-1">
								<Col className="bg-secondary text-light ms-1" sm="2">{t('global.labels.username')}/{t('global.labels.email')}</Col>
								<Col className="bg-secondary text-light ms-1" >{t('global.labels.roles')} </Col>
								<Col className="bg-secondary text-light ms-1 text-center" md="1" sm="2">{t('global.labels.actions')}</Col>
							</Row>
							{console.log(filterAppliedList)}
							{user_list
								.filter(i => (
									(filterAppliedList.systemgroups.length === 0 || userHasGroup(i, filterAppliedList.systemgroups))
									&& (filterAppliedList.contractgroups.length === 0 || userHasContractGroup(i, filterAppliedList.contractgroups))
									&& (filterAppliedList.contract.length === 0 || (i.contractgroups && i.contractgroups.some(r => (filterAppliedList.contract.indexOf(r.contract_id) >= 0))))
									&& !i.disabled
								)
								)
								.map((i, key) => (
									<OneUser i={i} key={key} ui_list={ui_list} t={t} app_roles={app_roles} c_list={c_list} index={key} domain={domain} user_list={user_list} setUserList={setUserList} functions={{ handleDelete: handleDelete }} not_contract={not_contract} />
								))
							}
						</>
						: <i> {t('users.messages.no_user')}</i>
					}
				</>
			}

		</>
	)
}


const userHasGroup = (i, group_list) => {
	if (i.systemgroups && i.systemgroups.some(r => group_list.indexOf(r) >= 0)) {
		return true
	}
	return false
}

const userHasContractGroup = (i, group_list) => {
	if (i.contractgroups && i.contractgroups.some(c => c.permissiongroup && c.permissiongroup.some(r => group_list.indexOf(r) >= 0))) {
		return true
	}
	return false
}


////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * return filter section with modal open button and button for filterered parameters
 * @param {t, ui_list, filterAppliedList, setFilterAppliedList} param0 
 */
export const ModalFilter = ({ t, ui_list, filterAppliedList, setFilterAppliedList, defaultfilter, contract_list, not_contract }) => {
	//	const { intervention_dict } = useContext(InterventionContext)

	const [showFilter, setShowFilter] = useState(false)


	const handleCloseFilter = () => {
		setShowFilter(false)
	}

	const resetAppliedFilter = (section) => {
		if (section) {
			setFilterAppliedList({ ...filterAppliedList, [section]: [] })
		} else {
			setFilterAppliedList(defaultfilter)
		}
		//		setShowFilter(false)
	}

	const updateAppliedFilter = (section, elem) => {
		const c = filterAppliedList[section].indexOf(elem)
		let f_data = [...filterAppliedList[section]]
		if (c === -1) {
			f_data.push(elem)
		} else {
			f_data.splice(c, 1)
		}
		setFilterAppliedList({ ...filterAppliedList, [section]: f_data })
	}


	return (
		<>

			<ButtonGroup>
				<Button size="sm" variant="secondary" onClick={() => setShowFilter(true)}><FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}</Button>
				{filterAppliedList.contract.length > 0 ?
					<>
						{contract_list.map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.contract.indexOf(d.id) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.contract.indexOf(d.id) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('contract', d)}>
								{d.name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.contract.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('contract')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
				{!not_contract && filterAppliedList.systemgroups.length > 0 ?
					<>
						{Object.keys(ui_list.group_dict).map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.systemgroups.indexOf(d) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.systemgroups.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('systemgroups', d)}>
								{ui_list.group_dict[d].name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.systemgroups.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('systemgroups')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
				{!not_contract && filterAppliedList.contractgroups.length > 0 ?
					<>
						{Object.keys(ui_list.group_dict).map((d, k) => (
							<Button key={k} size="sm" className={filterAppliedList.contractgroups.indexOf(d) !== -1 ? '' : 'd-none'}
								variant={filterAppliedList.contractgroups.indexOf(d) === -1 ? "outline-secondary" : "secondary"}
								onClick={() => updateAppliedFilter('contractgroups', d)}><Badge>Contratto</Badge>
								{ui_list.group_dict[d].name} <FontAwesomeIcon icon="times" />
							</Button>
						))}
						{filterAppliedList.contractgroups.length > 1 ?
							<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('contractgroups')}><FontAwesomeIcon icon="times" /></Button>
							: ''}
					</>
					: ''}
			</ButtonGroup>




			<Modal show={showFilter} onHide={handleCloseFilter} centered size="lg">
				<Modal.Header>
					<Modal.Title>
						<FontAwesomeIcon icon="filter" /> {t('global.labels.filters')}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						{contract_list ?
							<>
								<Row>
									<Col>
										{t('global.labels.contract')}
									</Col>
								</Row>
								<Row>
									<Col>
										{contract_list.map((d, k) => (
											<Button size="sm" key={k}
												variant={filterAppliedList.contract.indexOf(d.id) === -1 ? "outline-secondary" : "secondary"}
												onClick={(e) => updateAppliedFilter('contract', d.id)}>
												{d.name}
											</Button>
										))}
										{filterAppliedList.contract.length > 0 ?
											<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('contract')}><FontAwesomeIcon icon="times" /></Button>
											: ''}
									</Col>
								</Row>
							</>
							: <Row>
								<Col>
									<i>nessun contratto presente nella licenza</i>
								</Col>
							</Row>}
						<Row>
							<Col>
								{t('global.labels.roles')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(ui_list.group_dict).map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.systemgroups.indexOf(ui_list.group_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('systemgroups', ui_list.group_dict[d].id)}>
										{ui_list.group_dict[d].name}
									</Button>
								))}
								{filterAppliedList.systemgroups.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('systemgroups')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
						<Row>
							<Col>
								{t('global.labels.contract_roles')}
							</Col>
						</Row>
						<Row>
							<Col>
								{Object.keys(ui_list.group_dict).map((d, k) => (
									<Button size="sm" key={k}
										variant={filterAppliedList.contractgroups.indexOf(ui_list.group_dict[d].id) === -1 ? "outline-secondary" : "secondary"}
										onClick={(e) => updateAppliedFilter('contractgroups', ui_list.group_dict[d].id)}>
										{ui_list.group_dict[d].name}
									</Button>
								))}
								{filterAppliedList.contractgroups.length > 0 ?
									<Button size="sm" variant="outline-secondary" onClick={() => resetAppliedFilter('contractgroups')}><FontAwesomeIcon icon="times" /></Button>
									: ''}
							</Col>
						</Row>
					</Container>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseFilter}>
						{t('modal.close')}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}



export default translate()(ElementFormUsers)