import axios from 'axios';
import { GFUNC_DOMAIN } from '../constants/cloudfunctionparams'

import { auth } from '../../Firebase2/firebaseIndex';
///////////////////////////////////////////////////////////////////////////////
//
//	ricalcola lo stato e i summary delle stanze per cui è stato programmato un cambio
//	i dati vengono processati come DELTA rispetto ai dati esistenti
//			domainId: string, 
//			contractId: string, 
//			structureId: string
//					
///////////////////////////////////////////////////////////////////////////////
export const processRoomsStatus = async (domainId: string, contractId: string, structureId: string) => {
	const authToken = auth.currentUser ? await auth.currentUser.getIdToken(/* forceRefresh */ true) : null
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };

	const data = {
		headers: { Authorization: `Bearer ${authToken}` },
		params: {
			domainId: domainId,
			contractId: contractId,
			structureId: structureId
		}
	};

	return axios
		.post(`${GFUNC_DOMAIN}/processRoomsStatus`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
					console.log(error.response.data)
				} else {
					console.log("unknown error", error)
				}
			}
			return { error: error };
		});
}

///////////////////////////////////////////////////////////////////////////////
//
//	ricalcola lo stato e i summary delle stanze di tutta una struttura
//	i dati vengono tutti ricalcolati da zero
//			domainId: string, 
//			contractId: string, 
//			structureId: string
//					
///////////////////////////////////////////////////////////////////////////////
export const reconcileRooms = async (domainId: string, contractId: string, structureId: string) => {
	const authToken = auth.currentUser ? await auth.currentUser.getIdToken(/* forceRefresh */ true) : null
	axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };

	const data = {
		headers: { Authorization: `Bearer ${authToken}` },
		params : {
		domainId: domainId,
		contractId: contractId,
		structureId: structureId
		}
	};

	return axios
		.post(`${GFUNC_DOMAIN}/reconcileRooms`, data)
		.then((response) => {
			console.log("response data: ", response.data);
			return response.data;
		})
		.catch((error) => {
			if (error.response !== undefined) {
				if (error.response.status === 500) {
					console.log("error 500: ", error);
					console.log(error.response.data)
				} else {
					console.log("unknown error", error)
				}
			}
			return { error: error };
		});
}